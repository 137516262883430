import { useEffect, useState } from 'react';
import { actionCreators } from "../../store/comments";
import { Button, Box, Text } from 'rebass';
import ExistingComment from './existingComment';
import NewComment from './newComment';
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from '../../store';
import { IComment } from '../../models/comment';
import moment from 'moment';

const compareFn = (a:IComment, b: IComment) => moment.unix(a.dateCreated).diff(moment.unix(b.dateCreated))

export const CommentsContainer = (props: any) => {

    const comments = useSelector((store: IAppState) => store.com.comments);
    const currentUser = useSelector((store: IAppState) => store.auth.currentUser);
    const [itemId, setItemId] = useState<string | undefined>();

    const [showNewCommentBox, setShowNewCommentBox] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const windowUrl = window.location.href.split("/");
        const lastIndex = windowUrl.length - 1;
        const itemId = windowUrl[lastIndex];
        if (itemId){
            setItemId(itemId as string)
            dispatch(actionCreators.getComments(props.itemType, itemId));
        }
    }, [props.itemType, dispatch]);

    const renderExistingComments = () => {
        return comments.sort(compareFn).map((x, i) => <ExistingComment key={i} comment={x} />);
    }

    const submitComment = (newComment: any) => {
        if (newComment.name && newComment.comment) {
            newComment.itemId = itemId; // is this wrong?
            newComment.dateCreated = Date.now();
            dispatch(actionCreators.createComment(props.itemType, itemId as string, newComment));
            setShowNewCommentBox(false);
        }
    }

    const toggleNewCommentBox = () => setShowNewCommentBox(!showNewCommentBox);

    return <Box fontFamily="Lato">
        {!showNewCommentBox
            ? <Button mt={2} bg={'rgb(110, 55, 255)'} color='white' onClick={toggleNewCommentBox}>Add Comment</Button>
            : null}
        {showNewCommentBox
            ? <NewComment submitComment={submitComment}
                closeCommentBox={() => setShowNewCommentBox(false)}
                currentUser={currentUser} />
            : null
        }
        <Box mt={2}>
            {comments && comments.length > 0
                ? renderExistingComments()
                : <Text>There are currently no comments</Text>
            }
        </Box>
    </Box>
}
export default CommentsContainer;