const SubmitSummary = () => {
    return <div className="container" style={{ textAlign: 'center' }}>
        <h3>Thank you for submitting your response</h3>
        <div>
            {/* <p><input type="checkbox" /> Notify me when others complete this form</p>
            <p><input type="checkbox" /> Notify me when the owner decides on a final date</p> */}
        </div>
        <div ><a href='/'>Home</a></div>
    </div>
}
export default SubmitSummary;
