import React from 'react'
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
    Auth,
    User,
    getAuth as firebaseGetAuth,
    GoogleAuthProvider,
    setPersistence,
    browserSessionPersistence,
    signInWithPopup,
    AuthProvider,
} from 'firebase/auth';
import axios from 'axios';
import { actionCreators } from '../../store/auth';
import { useDispatch } from "react-redux"
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from "firebase/functions";
import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};


export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app)
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const sendAdminMessage = httpsCallable(functions, "sendPollCreatedEmail");
export const addCustomClaim = httpsCallable(functions, "addCustomClaim");
export const deleteFirebaseAuthUser = httpsCallable(functions, "deleteFirebaseAuthUser");
export const cancelPro = httpsCallable(functions, "cancelPro");
export const upgradeToPro = httpsCallable(functions, "upgradeToPro");
if (process.env.NODE_ENV !== "production") {
    connectFunctionsEmulator(functions, 'localhost', 5001)
}

const auth = firebaseGetAuth(app);

export const getAuth = () => auth;

export interface IAuthContext {
    ready: boolean;
    authUser: User | null | undefined;
    signInAuth: () => Promise<void>;
    signOutAuth: () => Promise<void>;
    accessToken: string;
}

export const useFirebase = () => {
    const [fbAuth, setFbAuth] = React.useState<Auth | undefined>();
    const [authUser, setAuthUser] = React.useState<User | null | undefined>();
    const [ready, setReady] = React.useState(false);
    const [provider, setProvider] = React.useState<AuthProvider | null | undefined>();
    const dispatch = useDispatch();
    const [accessToken, setAccessToken] = React.useState("");


    React.useEffect(() => {
        setFbAuth(getAuth());
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/calendar.readonly');
        setProvider(provider);
        const sessAccessToken = sessionStorage.getItem('calAccessToken') // Have to store because can't get access Token except during sign in.
        if (sessAccessToken) {
            setAccessToken(sessAccessToken);
        }
        setPersistence(getAuth(), browserSessionPersistence);  //Change if you want to keep even on close app. However lose access to accessToken
        return () => {
            setFbAuth(undefined);
        };
    }, []);



    React.useEffect(() => {
        if (authUser && fbAuth) {
            authUser.getIdToken().then((idToken) => {
                axios.defaults.headers.common['firebasetoken'] = idToken // for all requests
                dispatch(actionCreators.getUserByFirebaseId(fbAuth?.currentUser));
                setReady(true);
            })
        }

    }, [authUser, fbAuth, dispatch])

    React.useEffect(() => {
        if (fbAuth) {
            setReady(false)
            const unsubscribe = fbAuth.onAuthStateChanged(async (authUser) => {
                if (authUser) {
                    setAuthUser(authUser)
                } else {
                    setAuthUser(null)
                    setReady(true);
                }

            });
            return () => {
                unsubscribe();
            };
        }
        return () => {

        }

    }, [fbAuth]);


    const signInAuth = async () => {
        if (provider) {
            const userCredential = await signInWithPopup(getAuth(), provider);
            const oAuthCredential = GoogleAuthProvider.credentialFromResult(userCredential);
            if (oAuthCredential) {
                const token = oAuthCredential?.accessToken as string;
                setAccessToken(token)
                sessionStorage.setItem('calAccessToken', token);
            } else {
                console.error('Error Signing in')
            }

            // await addCalAccessToken(result.credential.accessToken)
            // axios.defaults.headers.common['accesstoken'] = result.credential.accessToken // for all requests
        }

    }

    const signOutAuth = async () => {
        await fbAuth?.signOut()
        sessionStorage.removeItem('calAccessToken')
        delete axios.defaults.headers.common['firebasetoken'];
    }

    return { ready, authUser, signInAuth, signOutAuth, accessToken }
}


export const FireBaseAuthContext = React.createContext<IAuthContext | null>(null);

export const FireBaseAuth = (props: any) => {
    const { ready, authUser, signInAuth, signOutAuth, accessToken } = useFirebase();
    return (
        <FireBaseAuthContext.Provider value={{ ready, authUser, signInAuth, signOutAuth, accessToken }}>
            {props.children}
        </FireBaseAuthContext.Provider>
    )
}

