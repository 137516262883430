import { Box, Text, Flex } from "rebass";

const Address = ({showMobileMap, setShowMobileMap, place}: any) => {
    return <Text fontFamily="Lato" fontSize={[1]}>
        <Flex style={{ 'cursor': 'pointer' }} onClick={() => setShowMobileMap(!showMobileMap)}>
            <Box ml={"-5px"} mr={1}><img height="18" width="18" alt="locationicon" src={require('../../../../images/baseline_location_on_black_18dp.png')} /></Box>
            <Box color={"darkslateblue"}> {place}</Box>
        </Flex>
    </Text>
}

export default Address;