import { Card, Heading, Text, Flex } from "rebass";

const CookiePolicy = () => {

    return <div className="container"  >
        <Flex>
            <Card
                width={[1]}
                p={[3, 5]}
                my={[3, 5]}
                mx="auto"
                bg="#f6f6ff"
                fontFamily="Lato"
            >
                <Text fontSize={[4]} fontFamily="Lato" color="#6e37ff">Konqur.co Cookie Policy</Text>
                <Text fontFamily="Lato">
                    <Text my={2}>Cookies consist of portions of code installed in the browser that assist the Owner in providing the Service according to the purposes described. Some of the purposes for which Cookies are installed may also require the User's consent.</Text>
                    <Text my={2}>
                        Where the installation of Cookies is based on consent, such consent can be freely withdrawn at any time following the instructions provided in this document.</Text>

                    <Heading my={2} fontSize={[3]}>Technical Cookies and Cookies serving aggregated statistical purposes</Heading>
                    <Heading my={2} fontSize={[2]}>Activity strictly necessary for the functioning of the Service</Heading>
                    <Text my={2}>This Application uses Cookies to save the User's session and to carry out other activities that are strictly necessary for the operation of this Application, for example in relation to the distribution of traffic. </Text>
                    <Heading my={2} fontSize={[2]}>Activity regarding the saving of preferences, optimization, and statistics</Heading>

                    <Text my={2}>
                        This Application uses Cookies to save browsing preferences and to optimize the User's browsing experience. Among these Cookies are, for example, those used for the setting of language and currency preferences or for the management of first party statistics employed directly by the Owner of the site.
                    </Text>
                    <Heading my={2} fontSize={[3]}>Other types of Cookies or third parties that install Cookies</Heading>
                    <Text my={2}>Some of the services listed below collect statistics in an anonymized and aggregated form and may not require the consent of the User or may be managed directly by the Owner - depending on how they are described - without the help of third parties.</Text>
                    <Text my={2}>If any third party operated services are listed among the tools below, these may be used to track Users’ browsing habits – in addition to the information specified herein and without the Owner’s knowledge. Please refer to the privacy policy of the listed services for detailed information.</Text>
                    <Heading my={2} fontSize={[2]}>Displaying content from external platforms</Heading>
                    <Text my={2}>
                        This type of service allows you to view content hosted on external platforms directly from the pages of this Application and interact with them.
                        This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.
                    </Text>
                    <Heading my={2} fontSize={[2]}> Mapbox widget (Mapbox Inc.)</Heading>

                    <Text my={2}>Mapbox is a maps visualization service provided by Mapbox Inc. that allows this Application to incorporate content of this kind on its pages.</Text>

                    <Text my={2}>Personal Data collected: Cookies and Usage Data.</Text>

                    <Text my={2}>Place of processing: United States – <a href="https://www.mapbox.com/legal/privacy/">Privacy Policy</a>.</Text>

                    <Heading my={2} fontSize={[2]}> Google Calendar widget (Google Ireland Limited)</Heading>
                    <Text my={2}>Google Calendar widget is a calendar content visualization service provided by Google Ireland Limited that allows this Application to incorporate content of this kind on its pages.</Text>

                    <Text my={2}>Personal Data collected: Cookies and Usage Data.</Text>

                    <Text my={2}>Place of processing: Ireland – <a href="https://policies.google.com/privacy">Privacy Policy</a>. Privacy Shield participant.</Text>

                    {/* <Heading my={2} fontSize={[2]}>Interaction with external social networks and platforms</Heading>
            
                <Text my={2}>This type of service allows interaction with social networks or other external platforms directly from the pages of this Application.
                The interaction and information obtained through this Application are always subject to the User’s privacy settings for each social network.
                This type of service might still collect traffic data for the pages where the service is installed, even when Users do not use it.
                It is recommended to log out from the respective services in order to make sure that the processed data on this Application isn’t being connected back to the User’s profile.</Text>

                <Heading my={2} fontSize={[2]}>Twitter Tweet button and social widgets (Twitter, Inc.)</Heading>
            
                <Text my={2}> Twitter Tweet button and social widgets are services allowing interaction with the Twitter social network provided by Twitter, Inc.</Text>
            
                <Text my={2}>Personal Data collected: Cookies and Usage Data.</Text>
            
                <Text my={2}>Place of processing: United States – <a href="https://twitter.com/en/privacy">Privacy Policy</a>. Privacy Shield participant.</Text>

                <Heading my={2} fontSize={[2]}>Facebook Like button and social widgets (Facebook, Inc.)</Heading>
            
                <Text my={2}> The Facebook Like button and social widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc.</Text>
            
                <Text my={2}> Personal Data collected: Cookies and Usage Data.</Text>
            
                <Text my={2}> Place of processing: United States – <a href="https://www.facebook.com/privacy/explanation">Privacy Policy</a>. Privacy Shield participant.</Text> */}

                    <Heading my={2} fontSize={[3]}>How to provide or withdraw consent to the installation of Cookies</Heading>

                    <Text my={2}>In addition to what is specified in this document, the User can manage preferences for Cookies directly from within their own browser and prevent – for example – third parties from installing Cookies.
                        Through browser preferences, it is also possible to delete Cookies installed in the past, including the Cookies that may have saved the initial consent for the installation of Cookies by this website.
                    </Text>

                    <Text my={2}>With regard to Cookies installed by third parties, Users can manage their preferences and withdrawal of their consent by clicking the related opt-out link (if provided), by using the means provided in the third party's privacy policy, or by contacting the third party.</Text>

                    <Text my={2}>Notwithstanding the above, the Owner informs that Users may follow the instructions provided on the subsequently linked initiatives by the Your Online Choices (EU), the Network Advertising Initiative (US) and the Digital Advertising Alliance (US), DAAC (Canada), DDAI (Japan) or other similar services. Such initiatives allow Users to select their tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of these resources in addition to the information provided in this document.</Text>

                    <Heading my={2} fontSize={[3]} id="owner_of_the_data">Owner and Data Controller</Heading>
                    <Text my={2}><a href="https://www.konqur.co"> www.konqur.co </a> - Angel, London, UK.</Text>
                    <Text my={2}><b>Owner contact email:</b> admin@konqur.co</Text>

                    <Text my={2}>Since the installation of third-party Cookies and other tracking systems through the services used within this Application cannot be technically controlled by the Owner, any specific references to Cookies and tracking systems installed by third parties are to be considered indicative. In order to obtain complete information, the User is kindly requested to consult the privacy policy for the respective third-party services listed in this document.</Text>
                    <Text my={2}>Given the objective complexity surrounding the identification of technologies based on Cookies, Users are encouraged to contact the Owner should they wish to receive any further information on the use of Cookies by this Application.</Text>


                    <Heading my={2} fontSize={[3]}>Definitions and legal references</Heading>

                    <Heading my={2} fontSize={[2]}>Personal Data (or Data)</Heading>
                    <Text my={2}>Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</Text>

                    <Heading my={2} fontSize={[2]}>Usage Data</Heading>
                    <Text my={2}>Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</Text>

                    <Heading my={2} fontSize={[2]}>User</Heading>
                    <Text my={2}>The individual using this Application who, unless otherwise specified, coincides with the Data Subject.</Text>

                    <Heading my={2} fontSize={[2]}>Data Subject</Heading>
                    <Text my={2}>The natural person to whom the Personal Data refers.</Text>

                    <Heading my={2} fontSize={[2]}>Data Processor (or Data Supervisor)</Heading>
                    <Text my={2}>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</Text>

                    <Heading my={2} fontSize={[2]}>Data Controller (or Owner)</Heading>
                    <Text my={2}>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</Text>

                    <Heading my={2} fontSize={[2]}>This Application</Heading>
                    <Text my={2}>The means by which the Personal Data of the User is collected and processed.</Text>

                    <Heading my={2} fontSize={[2]}>Service</Heading>
                    <Text my={2}>The service provided by this Application as described in the relative terms (if available) and on this site/application.</Text>

                    <Heading my={2} fontSize={[2]}>European Union (or EU)</Heading>
                    <Text my={2}>Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.</Text>

                    <Heading my={2} fontSize={[2]}>Cookies</Heading>
                    <Text my={2}>Small sets of data stored in the User's device.</Text>

                    <Heading my={2} fontSize={[2]}>Legal information</Heading>

                    This privacy policy relates solely to this Application, if not stated otherwise within this document.


                </Text>
            </Card>
        </Flex ></div >

}

export default CookiePolicy;