import { useCallback, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { Flex, Box, Text } from 'rebass'
import './Calender.css'

export const Calender = ({ updateSelectedDates, selectedDates, calEvents }: any) => {
  const [currentView, setCurrentView] = useState<Moment>(moment());
  const [currentMonthDays, setCurrentMonthDays] = useState<number>(0);
  const [firstDayOfMonth, setFirstDayOfMonth] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string | undefined>();


  // React.createRef();
  const isLeapYear = useCallback(() => {
    let leap = false;

    // if (year is not divisible by 4) then(it is a common year)
    // else if (year is not divisible by 100) then(it is a leap year)
    // else if (year is not divisible by 400) then(it is a common year)
    // else(it is a leap year)
    if (currentView) {
      let currentyear = parseInt(currentView.format("YYYY"), 10);
      if (currentyear % 4 !== 0) {
        leap = false;
      } else if (currentyear % 100 !== 0) {
        leap = true;
      } else if (currentyear % 400 !== 0) {
        leap = false;
      } else {
        leap = true;
      }
      return leap;
    }
    return
  }, [currentView]);

  const getFirstDayOfMonth = useCallback(() => {
    //Get day of the week from moment for first day of month
    if (currentView) {
      const firstDay = moment()
        .year(parseInt(currentView.format("YYYY"), 10))
        .month(currentView.format("MMMM"))
        .date(1)
        .format("dddd")
      setFirstDayOfMonth(firstDay)
    }

  }, [currentView]);

  const getLengthOfMonth = useCallback(() => {
    let leap = isLeapYear(); //Check if leap year
    if (currentView) {
      let currentMonth = currentView.format("MMMM");
      if (leap && currentMonth === "February") {
        setCurrentMonthDays(29)
      } else if (currentMonth === "February") {
        setCurrentMonthDays(28)
      } else {
        //Assign correct number of days to the month.
        let longMonths = ["April", "June", "September", "November"];
        longMonths.includes(currentMonth)
          ? setCurrentMonthDays(30)
          : setCurrentMonthDays(31)
      }
    }

  }, [currentView, isLeapYear]);

  useEffect(() => {
    getLengthOfMonth();
    getFirstDayOfMonth();
  }, [getLengthOfMonth, getFirstDayOfMonth])

  const updateSelectedDay = useCallback((day: number) => {
    // get day
    // Get month and year from view
    if (currentView) {
      let currentMonth = currentView.month();
      let currentYear = currentView.year();
      let newDay = moment(new Date(currentYear, currentMonth, day));
      let newDateArray = [...selectedDates];
      let exists = false;
      let index = 0;
      newDateArray.forEach((x, i) => {
        if (x.format("DDMMYY") === newDay.format("DDMMYY")) {
          exists = true;
          index = i;
        }
      });

      exists ? newDateArray.splice(index, 1) : newDateArray.push(newDay);
      updateSelectedDates(newDateArray);
      setSelectedDate(moment(newDay).format("DD-MM-YYYY"))
    }

  }, [updateSelectedDates, currentView, selectedDates]);

  const goToPreviousMonth = () => {
    if (currentView) {
      const currentViewClone = currentView.clone()
      setCurrentView(currentViewClone.subtract(1, "months"))
      getLengthOfMonth();
      getFirstDayOfMonth();
    }
  };

  const goToNextMonth = () => {
    if (currentView) {
      const currentViewClone = currentView.clone()
      setCurrentView(currentViewClone.add(1, "months"))
      getLengthOfMonth();
      getFirstDayOfMonth();
    }
  };


  // Only show if mouse hovering?
  const renderCalEventsList = useMemo(() => {
    if (calEvents && calEvents.length > 0) {
      return <Box>
        <Text p={2} bg={"#C20041"} fontWeight={500} fontFamily="Lato" mb={2} color="white">Events from your calendar on this day</Text>
        {calEvents.filter((y: any) => selectedDate === moment(y.start).format("DD-MM-YYYY")).map((x: any, i: number) => {
          return <Box my={1} p={2} key={i} bg={"#22aa22"} color="white" >
            <Text fontFamily="Lato" >{x.summary} </Text>
            <Text fontFamily="Lato" fontSize={"10px"}>{moment(x.start).format("DD-MM-YYYY")} - {moment(x.end).format("DD-MM-YYYY")}</Text>
          </Box>
        })}
      </Box>
    }
    return <Box />
  }, [calEvents, selectedDate])

  const renderDays = useMemo(() => {
    function calculateExtraStartDays() {
      switch (firstDayOfMonth) {
        case "Tuesday":
          return 1;
        case "Wednesday":
          return 2;
        case "Thursday":
          return 3;
        case "Friday":
          return 4;
        case "Saturday":
          return 5;
        case "Sunday":
          return 6;
        default:
          return 0;
      }
    }

    const extraStart = calculateExtraStartDays()

    return new Array(currentMonthDays + extraStart)
      .fill('')
      .map((x, i) => {
        if (i < extraStart) {
          return <li key={i} />;
        } else {

          let day = i + 1 - extraStart;
          let currentMonth = currentView.month();
          let currentYear = currentView.year();
          let dt = moment(new Date(currentYear, currentMonth, day))
          let existingEvents = calEvents.filter((y: any) => dt.format("DD-MM-YYYY") === moment(y.start).format("DD-MM-YYYY")).length;
          return selectedDates.filter((x: any) => x.format("DDMMYY") === dt.format("DDMMYY")).length > 0
            ? (
              <li onClick={() => updateSelectedDay(day)}
                key={"day_" + day}
                className="selected"
              >
                <Flex>
                  <Box mx='auto' />
                  <Box>{day}</Box>
                  {existingEvents > 0
                    ? <Box ml={1}><span role="img" aria-label="bell">🔔</span></Box>
                    : null
                  }
                  <Box mx='auto' />
                </Flex>
              </li>
            ) : (
              <li
                key={"day_" + day}
                style={{ cursor: "Pointer" }}
                onClick={() => updateSelectedDay(day)}
                className="active"
              >
                <Flex>
                  <Box mx='auto' />
                  <Box>{day}</Box>
                  {existingEvents > 0
                    ? <Box ml={1}><span role="img" aria-label="bell">🔔</span></Box>
                    : null
                  }
                  <Box mx='auto' />
                </Flex>
              </li>
            );
        }
      });
  }, [selectedDates, calEvents, currentView, updateSelectedDay, currentMonthDays, firstDayOfMonth])

  return (
    <Box>
      <Flex>
        <Box width={[1, 3 / 4]}>
          <div className="month">
            <ul>
              <li className="prev">
                <span
                  style={{ cursor: "Pointer" }}
                  onClick={() => goToPreviousMonth()}
                >
                  &#10094;
                </span>
              </li>
              <li className="next">
                <span
                  style={{ cursor: "Pointer" }}
                  onClick={() => goToNextMonth()}
                >
                  &#10095;
                </span>
              </li>
              <li>
                {currentView.format("MMMM")}
                <br />
                <span style={{ fontSize: "18px" }}>
                  {currentView.format("YYYY")}
                </span>
              </li>
            </ul>
          </div>
          <ul className="weekdays">
            <li>Mo</li>
            <li>Tu</li>
            <li>We</li>
            <li>Th</li>
            <li>Fr</li>
            <li>Sa</li>
            <li>Su</li>
          </ul>
          <ul className="days">{renderDays}</ul>
        </Box>
        <Box ml={2} width={[1 / 4]} style={{ height: '440px', overflowY: 'auto' }} className="desktop">
          {renderCalEventsList}
        </Box>
      </Flex>
      <Box width={[1]} className="mobile">
        {renderCalEventsList}
      </Box>
    </Box>
  )
}

export default Calender;
