import React, { useState } from 'react';
import { Card, Text, Box, Heading } from "rebass";
import Address from './address';
import DescriptionMap from "../../../shared/descriptionMap";

const SummaryCard = ({ kq }: any) => {
    const [showMobileMap, setShowMobileMap] = useState(false);

    let { title, description, location } = kq;

    return <Card
        p={2}
        bg={"white"}>
        <Text fontFamily="Lato" fontSize={2} style={{ marginBottom: '0px', color: 'grey' }}>
            You have been invited to:
        </Text>
        <Heading fontWeight="normal" fontFamily="Lato" fontSize={5}>{title} </Heading>
        <Text fontSize={2} fontFamily="Lato" >{description}</Text>
        {location && location.place_name
            ? <React.Fragment>
                <Address
                    showMobileMap={showMobileMap}
                    setShowMobileMap={setShowMobileMap}
                    place={location.place_name} />
                <Box my={1} />
                <DescriptionMap location={location} />
            </React.Fragment>

            : null
        }

    </Card>

}

export default SummaryCard;