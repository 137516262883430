
import { Text, Button } from 'rebass'

const DashboardModal = ({ title, modalName, onDeleteEvent, id }: { title: string, modalName: string, onDeleteEvent: (id: string) => void, id: string }) => {
    return <div className="modal fade" id={modalName} data-backdrop="false" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 style={{ fontFamily: 'Lato' }} className="modal-title" id="exampleModalLabel">Delete Konqur Poll: {title}?</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div style={{ fontFamily: 'Lato' }} className="modal-body">
                    Are you sure you want to delete this konqur poll?
                </div>
                <div className="modal-footer">
                    <Button
                        width={75} my={1} mx={1}
                        className="deletePollBackButton"
                        data-dismiss="modal"
                        bg="#6c757d">
                        <Text fontSize={1} fontFamily="Lato">Cancel</Text>
                    </Button>
                    <Button className="deletePollButton" onClick={() => onDeleteEvent(id)} width={75} my={1} mx={2} bg='red'>
                        <Text fontSize={1} fontFamily="Lato">Delete</Text>
                    </Button>

                </div>
            </div>
        </div>
    </div >
}
export default DashboardModal;