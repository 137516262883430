import { Timestamp } from "firebase/firestore";
import { IPollUserResponse } from "../models/poll";

const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const Utils = {
  validateEmail: (email: string) => {
    const trimmedEmail = email.trim();
    return re.test(trimmedEmail)
  },
  findMatchingResponses: (userResponses: IPollUserResponse[], item: Timestamp) => {
    let eR: string[] = [];
    if (userResponses.length > 0) {
      userResponses.forEach((x, i) => {
        x.responses.forEach((y, j) => {
          if (
            y.date.seconds === item.seconds &&
            y.response === true
          ) {
            eR.push(x.name);
          }
        });
      });
    }
    return eR;
  }
}