import { Button } from 'rebass'
import './buttons.css'

const PollHomeButton = ({ updateStatus }: { updateStatus: (status: string) => void }) => {
    return <Button
        fontWeight="normal"
        fontFamily="Lato"
        className="homeButton"
        onClick={() => updateStatus("landing")} bg="#4CDC4C">
        Back to start
    </Button>
}

export default PollHomeButton;