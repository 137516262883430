import React from 'react';
import PollDescription from './pollDescription'
import PollDatePicker from './pollDatePicker'
import PollConfirm from './pollConfirm';
import PollSummary from './pollSummary';
import {
    actionCreators, PollActionTypes
} from '../../../store/polls'
import { useDispatch, useSelector } from 'react-redux';
import { FireBaseAuthContext, IAuthContext } from "../../firebase/firebase";
import { IAppState } from '../../../store';
import { getFireBaseTimeStamp, getFireBaseTimeStampFromMomentDate } from '../../firebase/utils';
import { IPoll } from '../../../models/poll';
import { useNavigate } from 'react-router-dom';

const PollCreationContainer = (props: any) => {
    const dispatch = useDispatch();
    const [searchResults, setSearchResults] = React.useState([])
    const [adminEmail, setAdminEmail] = React.useState("");
    const [notifyAdminResponse, setNotifyAdminResponse] = React.useState(false);
    const [privateEvent, setPrivateEvent] = React.useState(false);
    const navigate = useNavigate();

    const notifyAdminResponseChange = () => setNotifyAdminResponse(!notifyAdminResponse)
    const updateAdminEmail = (email: string) => setAdminEmail(email);
    const updateEventPrivacy = (eventPrivacy: boolean) => setPrivateEvent(eventPrivacy);

    const formStatus = useSelector((state: IAppState) => state.cre.formStatus);
    const title = useSelector((state: IAppState) => state.cre.title);
    const description = useSelector((state: IAppState) => state.cre.description);
    const location = useSelector((state: IAppState) => state.cre.location);
    const selectedDates = useSelector((state: IAppState) => state.cre.selectedDates);
    const calEvents = useSelector((state: IAppState) => state.cre.calEvents)
    const url = useSelector((state: IAppState) => state.cre.url);
    const currentUser = useSelector((state: IAppState) => state.auth.currentUser);
    const { accessToken, authUser, } = React.useContext(FireBaseAuthContext) as IAuthContext;

    React.useEffect(() => {
        if (accessToken) {
            dispatch(actionCreators.getGCalEvents(accessToken, false));
        }
    }, [accessToken, dispatch])

    React.useEffect(() => {
        if (formStatus === 'describe') {
            dispatch({ type: PollActionTypes.RESET_FORM });
        }
    }, [formStatus, dispatch])

    const updateLocation = (e: any) => {
        let location = e.target.value
        let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target.value}.json`
        url += `?access_token=${process.env.REACT_APP_MAPBOX_KEY}`
        url += '&autocomplete=true'

        fetch(url)
            .then(response => response.json())
            .then(results => {
                results.features
                    ? setSearchResults(results.features)
                    : setSearchResults([])
            });


        dispatch({ type: PollActionTypes.UPDATE_FORM_LOCATION, location });
    }

    const selectLocation = (location: any) => dispatch({ type: PollActionTypes.UPDATE_FORM_LOCATION, location });


    const createPoll = (formStatus: string) => {
        let kq_obj = {
            title,
            description,
            location,
            dates: selectedDates.map(x => getFireBaseTimeStampFromMomentDate(x as any)),
            adminEmail,
            adminNotify: notifyAdminResponse,
            anonEvent: authUser && authUser.uid ? false : true, // Used for rules engine (If created anon)
            privateEvent, // Will be pro feature
            userResponses: [],
            user: authUser && authUser.uid ? authUser.uid : null,
            dateCreated: getFireBaseTimeStamp(),
            lastUpdated: getFireBaseTimeStamp(),
        } as IPoll
        dispatch(actionCreators.createPoll(formStatus, kq_obj));
    }



    const updateAndReset = () => {
        dispatch({ type: PollActionTypes.UPDATE_FORM_STATUS, formStatus: 'describe' });
        dispatch({ type: PollActionTypes.RESET_FORM });
        navigate(-1);
    }



    const updateFilter = (formStatus: string) => { //Only used by first form.
        if (formStatus === 'landing') {
            updateAndReset()
        } else {
            dispatch({ type: PollActionTypes.UPDATE_FORM_STATUS, formStatus });
        }
    }

    const updateFormStatus = (formStatus: string) => dispatch({ type: PollActionTypes.UPDATE_FORM_STATUS, formStatus })
    const updateSelectedDates = (selectedDates: any) => dispatch({ type: PollActionTypes.UPDATE_SELECTED_DATES, selectedDates });
    const updateTitle = (title: string) => dispatch({ type: PollActionTypes.UPDATE_FORM_TITLE, title});
    const updateDescription = (description: string) => dispatch({ type: PollActionTypes.UPDATE_FORM_DESCRIPTION, description });

    const renderFormPart = () => {
        let formPart;
        switch (formStatus) {
            case 'describe':
                formPart = <PollDescription
                    title={title}
                    description={description}
                    location={location}
                    updateTitle={updateTitle}
                    updateDescription={updateDescription}
                    updateLocation={(e: any) => updateLocation(e)}
                    updateStatus={(status: string) => updateFilter(status)}
                    selectLocation={(item: any) => selectLocation(item)}
                    searchResults={searchResults}
                    privateEvent={privateEvent}
                    updateEventPrivacy={updateEventPrivacy}
                    currentUser={currentUser}
                    forwardStatus='datePick'
                    backStatus='landing'
                />
                break;
            case 'datePick':
                formPart = <PollDatePicker
                    updateStatus={(status: string) => updateFormStatus(status)}
                    forward='confirm'
                    back='describe'
                    calEvents={calEvents}
                    selectedDates={selectedDates}
                    updateSelectedDates={(selectedDates: any) => updateSelectedDates(selectedDates)}

                />
                break;
            case 'confirm':
                formPart = <PollConfirm
                    updateStatus={(status: string) => status === 'summary' ? createPoll(status) : updateFormStatus(status)}
                    updateAdminEmail={(email: string) => updateAdminEmail(email)}
                    forward='summary'
                    title={title}
                    description={description}
                    location={location}
                    selectedDates={selectedDates}
                    notifyAdminResponse={notifyAdminResponse}
                    notifyAdminResponseChange={() => notifyAdminResponseChange()}
                    adminEmail={adminEmail}
                    privateEvent={privateEvent}
                    back='datePick' />
                break;
            case 'summary':
                formPart = <PollSummary
                    url={url}
                    updateStatus={updateAndReset}
                    title={title}
                />

                break;
            default:
                formPart = <div />
                break 
        }
        return formPart 
    }


    return renderFormPart()
}
export default PollCreationContainer;