import { Box, Text, Flex } from "rebass";
import './landingContent.css'

const SecondaryContent = () => {
    return <Flex my={[5, 3]}>
        <Box mr={[0, 5]} >
            <Flex>
                <Box ml={2} width={['100px']} alignSelf="flex-start">
                    <svg style={{ float: "left", marginLeft: '-10px', marginRight: "10px" }} width="100%" height="100%" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4856 14.5638C13.4856 11.8355 13.4856 10.4714 14.0585 9.6828C14.5583 8.99495 15.3264 8.55146 16.172 8.46259C17.1414 8.3607 18.3228 9.04277 20.6856 10.4069L40.0356 21.5787C42.3984 22.9428 43.5797 23.6249 43.9762 24.5154C44.322 25.2921 44.322 26.1791 43.9762 26.9558C43.5797 27.8463 42.3983 28.5283 40.0356 29.8925L20.6856 41.0642C18.3228 42.4284 17.1414 43.1104 16.172 43.0086C15.3264 42.9197 14.5583 42.4762 14.0585 41.7883C13.4856 40.9997 13.4856 39.6356 13.4856 36.9073L13.4856 14.5638Z" fill="#C20041" />
                    </svg>
                </Box>
                <Box mt={2} >
                    <Box mb={2} fontFamily="Lato">
                        <Text fontSize={[4]} className="dp_heading2">Choose your own way</Text>
                        <Text mt={[0, 2, 3]} fontSize={[2, 3]} pr={1} fontWeight={500}
                            letterSpacing={"0.04em"}
                            color="white" >Different methods for different people</Text>
                    </Box>
                    <hr style={{ backgroundColor: 'white' }} />
                    <Box className="contentDetails" mt={3} fontFamily="Lato" letterSpacing={"0.04em"}>
                        <Text my={[1, 2, 3]} color="white" fontFamily='Lato' fontSize={[2, 3, 4]}>
                            <span style={{ fontSize: "25px", letterSpacing: '0.04em', marginRight: '5px', width: "100%", textAlign: "center" }} role="img" aria-label="Woman Detective">🕵️‍♀️</span>
                            <b style={{ color: "rgb(255, 1, 21)" }}>Anonymous</b> - No need to sign in and you'll have a poll up in seconds
                        </Text>
                        <Text my={[1, 2, 3]} color="white" fontFamily='Lato' fontSize={[2, 3, 4]}>
                            <span style={{ fontSize: "25px", letterSpacing: '0.04em', marginRight: '5px', width: "100%", textAlign: "center" }} role="img" aria-label="Laptop">💻</span>
                            <b style={{ color: "rgb(194, 0, 65)" }}>Registered</b> - Get extra features and keep track on who has responded
                        </Text>
                        <Text my={[1, 2, 3]} color="white" fontFamily='Lato' fontSize={[2, 3, 4]}>
                            <span style={{ fontSize: "25px", letterSpacing: '0.04em', marginRight: '5px', width: "100%", textAlign: "center" }} role="img" aria-label="Man Mage">🧙‍♂️</span>
                            <b style={{ color: "rgb(194, 0, 162)" }}>Pro</b> - Get extra features such as restricted and private polls
                        </Text>
                    </Box>
                </Box>
            </Flex>
        </Box>
    </Flex>

}

export default SecondaryContent;