

import moment from 'moment'
import { Utils } from '../utils/utils';
import { db } from '../components/firebase/firebase';
import { IPollUserResponse, IPoll, IPoll2 } from '../models/poll';
import { getDoc, collection, doc, setDoc, addDoc, query, where, getDocs, deleteDoc, Timestamp } from 'firebase/firestore';
import { FIRESTORE_COLLECTIONS } from './collections';
import deepcopy from 'deepcopy';
import { getDateFromFireBaseTimeStamp, getFireBaseTimeStamp } from '../components/firebase/utils';

export enum PollActionTypes {
    CREATE_POLL = 'CREATE_POLL',
    GET_POLL = 'GET_POLL',
    GET_POLLS = 'GET_POLLS',
    DELETE_POLL = 'DELETE_POLL',
    UPDATE_FORM_STATUS = 'UPDATE_FORM_STATUS',
    UPDATE_FORM_TITLE = 'UPDATE_FORM_TITLE',
    UPDATE_FORM_DESCRIPTION = 'UPDATE_FORM_DESCRIPTION',
    UPDATE_FORM_LOCATION = 'UPDATE_FORM_LOCATION',
    UPDATE_SELECTED_DATES = 'UPDATE_SELECTED_DATES',
    RESET_FORM = 'RESET_FORM',
    RESET_USER_RESPONSE = 'RESET_USER_RESPONSE',
    UPDATE_USER_NAME = 'UPDATE_USER_NAME',
    UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE',
    GET_GCAL_EVENTS_MERGE = 'GET_GCAL_EVENTS_MERGE',
    GET_GCAL_EVENTS_INITIAL = 'GET_GCAL_EVENTS_INITIAL',
}

export interface IPollState {
    readonly formStatus: string, // Check if validated , used form hook
    readonly title: string, // Move to form hook
    readonly description: string, // Move to form hook
    readonly location: { place_name: string | null, geom: string | null, id: string | null }, // Move to form hook
    readonly selectedDates: string[], // Move to form hook
    readonly url: string | undefined, // Create in page.
    readonly calEvents: any[],
    readonly userResponse: IPollUserResponse | undefined,
    readonly kq: IPoll2 | undefined, // Used for easier data manipulation (I think)
    readonly polls: IPoll[],
    readonly mostNumberPicked: number,

}

const initialState = {
    formStatus: 'describe',
    title: '',
    description: '',
    location: { place_name: '', geom: null, id: null },
    selectedDates: [],
    url: '',
    calEvents: [],
    userResponse: undefined,
    kq: undefined,
    polls: null,
    mostNumberPicked: 0,

}

export const actionCreators = {
    /**
     * @description Creates A New Poll in Database
     * @param status 
     * @param newPollItem 
     * @returns 
     */
    createPoll: (status: string, newPollItem: IPoll) => async (dispatch: any) => {
        const result = await addDoc(collection(db, FIRESTORE_COLLECTIONS.POLLS), newPollItem);
        dispatch({ type: PollActionTypes.CREATE_POLL, created_url: result.id });
        dispatch({ type: PollActionTypes.UPDATE_FORM_STATUS, 'formStatus': status });
    },
    /**
     * @description Retrieves a poll from database
     * @param pollId Poll Id
     * @returns 
     */
    getPoll: (pollId: string) => async (dispatch: any) => {
        const result = await getDoc(doc(collection(db, FIRESTORE_COLLECTIONS.POLLS), pollId));
        if (result.exists()) {
            const item = result.data() as IPoll;
            if (item) {
                item["id"] = result.id;
            }
            dispatch({ type: PollActionTypes.GET_POLL, poll: item });

        } else {
            console.info("Poll does not exist")
        }
    },
    /**
     * @description Gets all Polls Created By a specified User
     * @param userId User Id
     * @returns 
     */
    getUserCreatedPolls: (userId: string) => async (dispatch: any) => {
        const querySnapshot = await getDocs(query(collection(db, FIRESTORE_COLLECTIONS.POLLS), where("user", "==", userId)));
        const polls: IPoll[] = [];
        querySnapshot.forEach((doc: any) => {
            const item = doc.data() as IPoll;
            item["id"] = doc.id;
            polls.push(item);
        });
        dispatch({ type: PollActionTypes.GET_POLLS, polls });
    },
    /**
     * @description Deletes a poll and any comments
     * @param pollId 
     * @param userId 
     * @param getAfter 
     * @returns 
     */
    deletePoll: (pollId: string, userId: string, getAfter: boolean) => async (dispatch: any) => {
        const pollDocRef = doc(collection(db, FIRESTORE_COLLECTIONS.POLLS), pollId);
        const commentsCollectionRef = collection(pollDocRef, FIRESTORE_COLLECTIONS.COMMENTS);
        const querySnapshot = await getDocs(commentsCollectionRef);

        // let querySnapshot = await db.collection(FIRESTORE_COLLECTIONS.POLLS).doc(pollId).collection(FIRESTORE_COLLECTIONS.COMMENTS).get()
        let commentIds: string[] = [];
        querySnapshot.forEach((doc: any) => commentIds.push(doc.id));
        commentIds.forEach(async (cid) => {
            const commentDocRef = doc(commentsCollectionRef, cid)
            await deleteDoc(commentDocRef)
        });
        await deleteDoc(pollDocRef);
        dispatch({ type: PollActionTypes.DELETE_POLL });
        if (getAfter) {
            dispatch(actionCreators.getUserCreatedPolls(userId));
        }
    },
    /**
     * @description Retrieves users Gcal events and optionally merges with Poll
     * @param accessToken 
     * @param mergeWithPoll 
     * @returns 
     */
    getGCalEvents: (accessToken: string, mergeWithPoll: boolean) => async (dispatch: any) => {
        const timeMin = new Date().toISOString();
        fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${timeMin}&singleEvents=true&orderBy=startTime`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
        }).then(response => {
            response.json().then(data => {
                const events = data.items
                if (events && events.length) {
                    const calendarEvents = events.map((event: any, i: number) => {
                        return {
                            start: event.start.dateTime || event.start.date,
                            end: event.end.dateTime || event.end.date,
                            summary: event.summary
                        }
                    });
                    if (mergeWithPoll) {
                        //Cross compares with KQ and adds events to KQ. Used by poll response. 
                        dispatch({ type: PollActionTypes.GET_GCAL_EVENTS_MERGE, calEvents: calendarEvents })
                    } else {
                        dispatch({ type: PollActionTypes.GET_GCAL_EVENTS_INITIAL, calEvents: calendarEvents })
                    }

                }
            })

        }).catch(e => console.error("Error", e))

    },
    /**
     * @description Creates a reply to a Poll
     * @param name The users name
     * @param responses Existing Responses
     * @param pollId Poll ID
     * @param userId The users database ID
     * @returns 
     */
    createReply: (userResponse: IPollUserResponse, pollId: string) => async (dispatch: any) => {
        const pollRef = doc(collection(db, FIRESTORE_COLLECTIONS.POLLS), pollId);
        const poll = await getDoc(pollRef);
        if (poll.exists()) {
            let item = poll.data() as IPoll;
            item["id"] = poll.id;
            item.userResponses.push(userResponse)
            item.lastUpdated = getFireBaseTimeStamp()
            await setDoc(pollRef, item, { merge: true })
            let d = new Date();
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = "konqurid_" + pollId + "=" + pollId + ";" + expires + ";path=/";
            dispatch({ 'type': PollActionTypes.RESET_FORM })
        } else {
            console.info("This Poll does not exist. ")
        }
    }
}
const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PollActionTypes.UPDATE_FORM_STATUS:
            return {
                ...state,
                formStatus: action.formStatus,
            }
        case PollActionTypes.UPDATE_FORM_TITLE:
            return {
                ...state,
                title: action.title
            }
        case PollActionTypes.UPDATE_FORM_DESCRIPTION:
            return {
                ...state,
                description: action.description
            }
        case PollActionTypes.UPDATE_FORM_LOCATION:
            return {
                ...state,
                location: action.location
            }
        case PollActionTypes.UPDATE_SELECTED_DATES:
            return {
                ...state,
                selectedDates: [...action.selectedDates]
            }
        case PollActionTypes.RESET_FORM:
            return {
                ...state,
                title: '',
                description: '',
                location: { place_name: '', geom: null, id: null },
                selectedDates: [],
                url: undefined,
            }
        case PollActionTypes.CREATE_POLL:
            return {
                ...state,
                url: action.created_url
            }
        case PollActionTypes.GET_POLL:
            let { poll }: { poll: IPoll } = action;
            let kq: IPoll2 = {
                id: poll.id as string,
                title: poll.title,
                respondees: poll.userResponses.map((x: any) => x.name),
                description: poll.description,
                location: poll.location,
                privateEvent: poll.privateEvent ? poll.privateEvent : false,
                dates: poll.dates.map((x: Timestamp) => {
                    return {
                        date: x,
                        responses: Utils.findMatchingResponses(poll.userResponses, x),
                        calendar: []
                    }
                })
            }

            let mostNumberPicked = 0;
            kq.dates.forEach((x: any) => {
                if (x.responses.length >= mostNumberPicked) {
                    mostNumberPicked = x.responses.length
                }
            })

            let userResponse: IPollUserResponse = {
                name: "",
                responses: poll.dates.map((x: any) => {
                    return { date: x, response: false }
                })
            }

            return {
                ...state,
                userResponse,
                mostNumberPicked,
                kq
            }
        case PollActionTypes.GET_POLLS:
            return {
                ...state,
                polls: action.polls
            }
        case PollActionTypes.DELETE_POLL:
            return {
                ...state,
            }
        case PollActionTypes.RESET_USER_RESPONSE:
            return {
                ...state,
                userResponse: undefined,
            }
        case PollActionTypes.UPDATE_USER_NAME:
            let cloneUser: IPollUserResponse | undefined = state.userResponse ? deepcopy(state.userResponse) as IPollUserResponse : undefined;
            if (cloneUser) {
                cloneUser.name = action.name
            }
            return {
                ...state,
                userResponse: cloneUser
            }
        /**
         * This updates the users response for a particular date, e.g. was yes can come, now is no cannot come or vice versa. 
         */
        case PollActionTypes.UPDATE_USER_RESPONSE:
            let cloneUserResponse: IPollUserResponse | undefined = state.userResponse ? deepcopy(state.userResponse) as IPollUserResponse : undefined;

            if (cloneUserResponse) {
                let index = cloneUserResponse.responses.findIndex((x: any) => x.date.seconds === action.date.seconds)
                let cloneResponses = [...cloneUserResponse.responses]
                cloneResponses[index].response = !cloneResponses[index].response
                let userResponseObj = {
                    name: cloneUserResponse.name,
                    responses: cloneResponses
                }
                return {
                    ...state,
                    userResponse: userResponseObj
                }
            }
            return {
                ...state,
            }

        case PollActionTypes.GET_GCAL_EVENTS_INITIAL:
            return {
                ...state,
                calEvents: action.calEvents,
            }
        case PollActionTypes.GET_GCAL_EVENTS_MERGE:
            //Create range for each event (remmeber not last day!)

            let kqClone = state.kq ? deepcopy(state.kq) as IPoll2 : undefined;
            if (kqClone) {
                if (action.calEvents && action.calEvents.length > 0) {
                    let cd = action.calEvents.map((x: any) => {
                        var dates = [];
                        let currDate = moment(x.start, "YYYY-MM-DD").startOf('day');
                        let endDate = moment(x.end, "YYYY-MM-DD").startOf('day');
                        dates.push(currDate.clone().toDate()); //Add initial date
                        while (currDate.add(1, 'days').diff(endDate) < 0) {
                            dates.push(currDate.clone().toDate());
                        }
                        return {
                            dates,
                            summary: x.summary
                        }
                    })
                    cd.forEach((c: any) => {
                        c.dates.forEach((x: any) => {
                            kqClone?.dates.forEach((k: any, i: number) => {
                                if (getDateFromFireBaseTimeStamp(k.date).isSame(x, 'day')) {
                                    kqClone?.dates[i].calendar.push(c.summary)
                                }
                            })

                        })
                    })

                }
            }

            //For each event, each date in range, 
            // Check if that exsits int he kq dates.
            // If does add the summary. 

            return {
                ...state,
                calEvents: action.calEvents,
                kqClone,
            }

        default:
            return state;

    }
}

export default reducer;
