import { Card, Heading, Text, Box, Flex } from "rebass";

const Privacy = () => {

    return <div className="container"  > <Flex>
        <Card
            width={[1]}
            p={[3, 5]}
            my={[3, 5]}
            mx="auto"
            bg="#f6f6ff"
        >
            <Heading fontSize={[4]} fontFamily="Lato" color="#6e37ff">Konqur.co Privacy Policy</Heading>
            <Text fontFamily="Lato">
                <Text my={2}>This Application collects some Personal Data from its Users.</Text>

                <Heading my={2} fontSize={[3]} id="owner_of_the_data">Owner and Data Controller</Heading>
                <Text my={2}><a href="https://www.konqur.co"> www.konqur.co </a> - Angel, London, UK.</Text>
                <Text my={2}><b>Owner contact email:</b> admin@konqur.co</Text>
                <Heading my={2} fontSize={[3]}>Types of Data collected</Heading>
                <Text my={2}>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies; Usage Data; email address. </Text>
                <Text my={2}>Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.</Text>
                <Text my={2}>Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.</Text>
                <Text my={2}>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.</Text>
                <Text my={2}>Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</Text>
                <Text my={2}>Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</Text>
                <Heading my={3} fontSize={[4]}>Mode and place of processing the Data</Heading>
                <Heading my={2} fontSize={[3]}>Methods of processing</Heading>
                <Text my={2}>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.</Text>
                <Text>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated.</Text>
                <Heading my={2} fontSize={[3]}>Legal basis of processing</Heading>
                <Text>The Owner may process Personal Data relating to Users if one of the following applies:</Text>
                <Box>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}>Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}>Provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}>Processing is necessary for compliance with a legal obligation to which the Owner is subject;</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}>Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}>Processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</Text>
                    </Flex>
                </Box>
                <Text my={2}>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract. </Text>
                <Heading my={2} fontSize={[3]}>Place</Heading>
                <Text my={2}>The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.</Text>
                <Text my={2}>Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</Text>
                <Text my={2}>Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.</Text>
                <Text my={2}>If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</Text>
                <Heading my={2} fontSize={[3]}>Retention time</Heading>
                <Text my={2}>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</Text>
                <Text my={2}>Therefore:</Text>
                <Flex my={2}>
                    <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}>Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</Text>
                </Flex>
                <Flex my={2}>
                    <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}>Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</Text>
                </Flex>

                <Text my={2}>The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.</Text>
                <Text my={2}>Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</Text>

                <Heading my={3} fontSize={[4]}>The purposes of processing</Heading>
                <Text my={2}>The Data concerning the User is collected to allow the Owner to provide its Services, as well as for the following purposes: Registration and authentication, Displaying content from external platforms, Interaction with external social networks and platforms and Managing contacts and sending messages. </Text>
                <Text my={2}>Users can find further detailed information about such purposes of processing and about the specific Personal Data used for each purpose in the respective sections of this document.</Text>

                <Heading my={3} fontSize={[4]}>Detailed information on the processing of Personal Data</Heading>
                <Text my={2}>Personal Data is collected for the following purposes and using the following services:</Text>

                <Heading my={3} fontSize={[3]}>Registration and authentication</Heading>

                <Text my={2}>By registering or authenticating, Users allow this Application to identify them and give them access to dedicated services.</Text>
                <Text my={2}>This Application will be able to access some Data, stored by these third-party services, for registration or identification purposes.</Text>
                <Flex my={3} ml={2}>
                    <Box ml={4}> <span role="img" aria-label="heavy_tick">✔️</span></Box>
                    <Box ml={2}>
                        <Heading fontSize={[2]}>Google OAuth (Google Ireland Limited)</Heading>
                        <Text my={2}>Google OAuth is a registration and authentication service provided by Google Ireland Limited and is connected to the Google network.</Text>
                        <Text my={2}> Personal Data collected: various types of Data as specified in the privacy policy of the service. </Text>
                        <Text my={2}> Place of processing: Ireland &ndash;
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Privacy Shield participant. </Text>
                    </Box>
                </Flex>
                <Heading my={3} fontSize={[3]}>Displaying content from external platforms</Heading>
                <Text my={2}>This type of service allows you to view content hosted on external platforms directly from the pages of this Application and interact with them.</Text>
                <Text my={2}> This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.</Text>
                <Flex my={3} ml={2}>
                    <Box ml={4}> <span role="img" aria-label="heavy_tick">✔️</span></Box>
                    <Box ml={2}>
                        <Heading fontSize={[2]}>Mapbox widget (Mapbox Inc.)</Heading>
                        <Text my={2}>Mapbox is a maps visualization service provided by Mapbox Inc. that allows this Application to incorporate content of this kind on its pages.</Text>
                        <Text my={2}> Personal Data collected: Cookies; Usage Data. </Text>
                        <Text my={2}> Place of processing: United States &ndash; <a href="https://www.mapbox.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </Text>
                    </Box>
                </Flex>
                {/* <h4>Google Calendar widget (Google Ireland Limited)</h4>
                <Text my={2}>Google Calendar widget is a calendar content visualization service provided by Google Ireland Limited that allows this Application to incorporate content of this kind on its pages.</Text>
                <Text my={2}> Personal Data collected: Cookies; Usage Data. </Text> */}
                <Flex my={3} ml={2}>
                    <Box ml={4}> <span role="img" aria-label="heavy_tick">✔️</span></Box>
                    <Box ml={2}>
                        <Heading fontSize={[2]}>Google Calendar API data (Google Ireland Limited)</Heading>
                        <Text my={2}>Google Calendar API provides access to a users calendar events that allows this Application to incorporate content of this kind on its pages. Events are shown within this application to highlight dates to users that they are already busy on.</Text>
                        <Text>Data from this service is not stored, monitored or captured by the Application or the Owner</Text>
                        <Text my={2}> Personal Data collected: Usage Data; </Text>
                        <Text my={2}> Place of processing: Ireland &ndash; <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Privacy Shield participant. </Text>
                    </Box>
                </Flex>
                {/* <h3>Interaction with external social networks and platforms</h3>
                <Text my={2}>This type of service allows interaction with social networks or other external platforms directly from the pages of this Application.<br /> The interaction and information obtained through this Application are always subject to the User’s privacy settings for each social network.<br /> This type of service might still collect traffic data for the pages where the service is installed, even when Users do not use it.
                         <br /> It is recommended to log out from the respective services in order to make sure that the processed data on this Application isn’t being connected back to the User’s profile.</Text>
                <h4>Twitter Tweet button and social widgets (Twitter, Inc.)</h4>
                <Text my={2}>The Twitter Tweet button and social widgets are services allowing interaction with the Twitter social network provided by Twitter, Inc.</Text>
                <Text my={2}> Personal Data collected: Cookies; Usage Data. </Text>
                <Text my={2}> Place of processing: United States &ndash; <a href="https://twitter.com/en/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Privacy Shield participant. </Text>
                <h4>Facebook Like button and social widgets (Facebook, Inc.)</h4>
                <Text my={2}>The Facebook Like button and social widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc.</Text>
                <Text my={2}> Personal Data collected: Cookies; Usage Data. </Text>
                <Text my={2}> Place of processing: United States &ndash; <a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Privacy Shield participant. </Text> */}
                <Heading my={3} fontSize={[3]}>Managing contacts and sending messages</Heading>
                <Text my={2}>This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User.<br /> These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.</Text>
                <Flex my={3} ml={2}>
                    <Box ml={4}> <span role="img" aria-label="heavy_tick">✔️</span></Box>
                    <Box ml={2}>
                        <Heading fontSize={[2]}>Sendgrid (Sendgrid)</Heading>
                        <Text my={2}>Sendgrid is an email address management and message sending service provided by Sendgrid Inc.</Text>
                        <Text my={2}> Personal Data collected: email address. </Text>
                        <Text my={2}> Place of processing: United States &ndash; <a href="https://sendgrid.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. </Text>
                    </Box>
                </Flex>
                <Heading my={3} fontSize={[3]}>The rights of Users</Heading>
                <Text my={2}>Users may exercise certain rights regarding their Data processed by the Owner.</Text> <Text my={2}>In particular, Users have the right to do the following:</Text>

                <Box>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Withdraw their consent at any time.</b> Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Object to processing of their Data.</b> Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Access their Data.</b> Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Verify and seek rectification.</b> Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Restrict the processing of their Data.</b> Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it. </Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Have their Personal Data deleted or otherwise removed.</b> Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Receive their Data and have it transferred to another controller.</b> Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</Text>
                    </Flex>
                    <Flex my={2}>
                        <Box ml={4}><span role="img" aria-label="right_arrow">➡️</span></Box><Text ml={2}><b>Lodge a complaint.</b> Users have the right to bring a claim before their competent data protection authority.</Text>
                    </Flex>
                </Box>



                <Heading my={3} fontSize={[3]}>Details about the right to object to processing</Heading>
                <Text my={2}>Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.</Text>
                <Text my={2}>Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document. </Text>
                <Heading my={3} fontSize={[3]}>How to exercise these rights</Heading>
                <Text my={2}>Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.</Text>
                <Heading my={3} fontSize={[3]}>Cookie Policy</Heading>
                <Text my={2}>This Application uses Cookies. To learn more and for a detailed cookie notice, the User may consult the <a href="//www.iubenda.com/private/privacy-policy/1510802/cookie-policy" title="Cookie Policy" className="show_comp_link iframe-preserve an-preserve">Cookie Policy</a>.</Text>
                <Heading my={3} fontSize={[3]}>Additional information about Data collection and processing</Heading>

                <Heading my={2} fontSize={[2]}>Legal action</Heading>
                <Text my={2}> The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.</Text>
                <Text my={2}>The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities. </Text>
                <Heading my={2} fontSize={[2]}>Additional information about User's Personal Data</Heading>
                <Text my={2}> In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request. </Text>

                <Heading my={2} fontSize={[2]}>System logs and maintenance</Heading>
                <Text my={2}> For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose. </Text>
                <Heading my={3} fontSize={[3]}>Information not contained in this policy</Heading>
                <Text my={2}> More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document. </Text>
                {/* <Heading my={3} fontSize={[3]}>How “Do Not Track” requests are handled</Heading>
                <Text my={2}> This Application does not support “Do Not Track” requests.<br />To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies. </Text> */}


                <Heading my={3} fontSize={[3]}>Changes to this privacy policy</Heading>
                <Text my={2}> The Owner reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.</Text>
                <Text my={2}>Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required. </Text>
                <Heading my={3} fontSize={[3]}>Definitions and legal references</Heading>
                <Heading my={2} fontSize={[2]}>Personal Data (or Data)</Heading>
                <Text my={2}>Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</Text>
                <Heading my={2} fontSize={[2]}>Usage Data</Heading>
                <Text my={2}>Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</Text>
                <Heading my={2} fontSize={[2]}>User</Heading>
                <Text my={2}>The individual using this Application who, unless otherwise specified, coincides with the Data Subject.</Text>
                <Heading my={2} fontSize={[2]}>Data Subject</Heading>
                <Text my={2}>The natural person to whom the Personal Data refers.</Text>
                <Heading my={2} fontSize={[2]}>Data Processor (or Data Supervisor)</Heading>
                <Text my={2}>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</Text>
                <Heading my={2} fontSize={[2]}>Data Controller (or Owner)</Heading>
                <Text my={2}>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</Text>
                <Heading my={2} fontSize={[2]}>This Application</Heading>
                <Text my={2}>The means by which the Personal Data of the User is collected and processed.</Text>
                <Heading my={2} fontSize={[2]}>Service</Heading>
                <Text my={2}>The service provided by this Application as described in the relative terms (if available) and on this site/application.</Text>
                <Heading my={2} fontSize={[2]}>European Union (or EU)</Heading>
                <Text my={2}>Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area. </Text>
                <Heading my={2} fontSize={[2]}>Cookies</Heading>
                <Text my={2}>Small sets of data stored in the User's device.</Text>
                <Heading my={3} fontSize={[3]}>Legal information</Heading>
                <Text my={2}>This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</Text>
                <Text my={2}>This privacy policy relates solely to this Application, if not stated otherwise within this document.</Text>
                <Text my={2}> Latest update: September 18, 2019 </Text>

            </Text>
        </Card>
    </Flex></div >

}

export default Privacy;