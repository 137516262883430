import { Box, Text, Flex } from "rebass";

const NameInput = ({userResponse, formClass, alreadyFilledOut, updateUserName}: any) => {
    return <Flex width={[1]}>
        <Box width={[1 / 4]} color="white" p={10} alignSelf="center">
            <Text fontFamily="Lato" fontSize={3} color="#6e37ff" fontWeight="Bold" textAlign="center">
                Name
            </Text>
        </Box>
        <Box textAlign="center" width={[3 / 4]} color="white" p={10}>
            <form className={formClass} noValidate>
                <div className="form-group" style={{ marginBottom: "0" }}>
                    <input value={userResponse.name}
                        onChange={updateUserName}
                        required
                        id="resp_name_mobile"
                        className="form-control"
                        disabled={alreadyFilledOut}
                        name="userName"
                        type="text" />
                    <div className="invalid-feedback">
                        <Text fontFamily="Lato" my={1} fontSize={[1]}>
                            Add your name!
                        </Text>
                    </div>
                </div>
            </form>
        </Box>
    </Flex>
}
export default NameInput;