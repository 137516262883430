
import { Heading, Box, Text, Flex } from "rebass";

const CookiePopUp = ({ close }: { close: () => void }) => {
    return <Box fontFamily={'Lato'} mx={[0, 3, 5]} p={2} bg={'black'} style={{ borderRadius: 5, opacity: 0.6 }}>
        <Flex>
            <Heading fontFamily='Lato' color={'white'} fontSize={1} fontWeight={600}> Cookies on Konqur.co</Heading>
            <Box flex='auto' />
            <Box style={{ cursor: 'pointer' }} onClick={close}>
                <span role="img" aria-label="closeCookie">❎</span></Box>
        </Flex>
        <Text color={'white'} my={1} fontSize={1} fontFamily={"Lato"}>
            We use cookies to make signing in an out easier.
            You are free to manage this via your browser settings at any time.
            To learn more about how we use the cookies, please see our cookies policy
        </Text>
    </Box >
}

export default CookiePopUp