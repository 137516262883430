import { Button, Text } from "rebass";
import './buttons.css'
import { Link } from 'react-router-dom'

const StartNewPollButton = ({ updateStatus }: { updateStatus: (status: string) => void }) => {
    return <Link to={`/poll/create`}>
        <Button
            className={'startNewPollButton'}
            bg={'#4CDC4C'}
            width={[1, "160px"]}
            onClick={() => updateStatus('describe')}
        >
            <Text fontSize={2} fontFamily="Lato">
                Start New Poll
            </Text>
        </Button>
    </Link>
}

export default StartNewPollButton;
