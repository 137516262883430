import { useContext } from 'react';
import { Card, Button, Heading, Text, Flex, Box } from 'rebass';
import { actionCreators, AuthActionTypes } from '../../store/auth';
import moment from 'moment';
import ProfileModal from './profileModal';
import CancelSubcriptionModal from './cancelSubscriptionModal';
import './buttons.css';
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { FireBaseAuthContext, IAuthContext } from "../firebase/firebase";
import { IAppState } from '../../store';

const ProfileContainer = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state: IAppState) => state.auth.currentUser);
    const chargeStatus = useSelector((state: IAppState) => state.auth.chargeStatus);
    const { authUser, signOutAuth } = useContext(FireBaseAuthContext) as IAuthContext;

    // const renderProInfo = () => {
    //     if (currentUser && currentUser.plan_tier === 'pro') {
    //         return <Box>
    //             <Flex my={2} ><Text fontFamily='Lato' fontWeight={600} width={'150px'} >Member Since</Text><Text fontFamily='Lato'>{moment.unix(currentUser.stripe_billing_anchor).format("DD/MM/YYYY")}</Text></Flex>
    //             <Flex my={2} ><Text fontFamily='Lato' fontWeight={600} width={'150px'} >Billing Date</Text><Text fontFamily='Lato'>The {moment.unix(currentUser.stripe_billing_anchor).format("Do")} of every month</Text></Flex>
    //             <Button my={4} fontFamily="Lato" bg='orange' data-toggle="modal" data-target={`#cancelSubscription`} className="deleteAcountButton">
    //                 Cancel Subscription
    //             </Button>
    //         </Box>
    //     }
    //     return <Link to={`/payment`}>
    //         <Button my={4} fontFamily="Lato" bg='#4CDC4C' className={'goProButton'}>
    //             Go Pro
    //     </Button>
    //     </Link>

    // }

    const deleteAccount = () => {
        dispatch(actionCreators.deleteUser(authUser, signOutAuth));
    }

    const cancelSubscription = () => {
        dispatch({ type: AuthActionTypes.UPDATE_PAYMENT_STATUS, 'status': "processing" });
        dispatch(actionCreators.cancelSubscription());
    }

    return <Box px={[2, 30, 50]} py={[0, 20, 30]} >
        <Card
            width={[1, 1, 0.8]}
            p={[3, 5]}
            mt={1}
            mb={[2, 3]}
            mx="auto"
            bg="#f6f6ff"
        >

            <Heading mb={4} color="#6e37ff" fontFamily="Lato">Profile</Heading>
            <Flex fontSize={[1, 2]} my={2}>
                <Text fontFamily='Lato' fontWeight={600} width={'140px'}>Name</Text>
                <Text fontFamily='Lato' >{currentUser ? currentUser.name : ''}</Text>
            </Flex>
            <Flex fontSize={[1, 2]} my={2}>
                <Text fontFamily='Lato' fontWeight={600} width={'120px'}>Email</Text>
                <Text fontFamily='Lato' >{currentUser ? currentUser.email : ''}</Text>
            </Flex>
            <Flex fontSize={[1, 2]} my={2} >
                <Text fontFamily='Lato' fontWeight={600} width={'140px'} >Member since</Text >
                <Text fontFamily='Lato'>{currentUser ? moment(currentUser.dateCreated).format("DD/MM/YYYY") : ''}</Text>
            </Flex>
            <hr />
            <Heading mb={4} color="#6e37ff" fontFamily="Lato">Subscription</Heading>
            <Flex my={2} ><Text fontFamily='Lato' fontWeight={600} width={'150px'} >Plan</Text><Text fontFamily='Lato' style={{ textTransform: 'capitalize' }}>{currentUser ? currentUser.plan_tier : ''}</Text></Flex>
            {/* {renderProInfo()} */}
            <hr />
            <Heading mb={4} color="#6e37ff" fontFamily="Lato">Account Deletion</Heading>
            <Button fontFamily='Lato' data-toggle="modal" data-target={`#deleteAccount`} className="deleteAcountButton" mt={2} bg='red'>
                Delete
            </Button>
            <ProfileModal deleteAccount={deleteAccount} modalName={`deleteAccount`} />
            <CancelSubcriptionModal chargeStatus={chargeStatus} id={"cancelSubscription"} cancelSub={cancelSubscription} modalName={`cancelSubscription`} />
        </Card>
    </Box>
}
export default ProfileContainer;