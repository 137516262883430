import './App.css';
import PollCreationContainer from './components/polls/creation/pollCreationContainer'
import About from './components/about/about'
import Login from './components/login/login';
import FillContainer from './components/polls/fill/fillContainer'
import DashboardContainer from './components/dashboard/dashboardContainer';
import 'react-dates/initialize';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Privacy from './components/privacy/privacy';
import ProfileContainer from './components/profile/profileContainer';
import Plans from './components/plans/plans';
import PaymentFormContainer from './components/payment/PaymentFormContainer';
import AuthoriseRoute from './components/AuthoriseRoute';
import LandingLayout from './LandingLayout';
import CookiePolicy from './components/privacy/cookiePolicy';
import EventResponseContainer from './components/event/response/EventResponseContainer';
import EventCreationContainer from './components/event/creation/eventCreationContainer'
import LandingPage from './components/landing/landingPage';
import NonLandingLayout from './NonLandingLayout';


const FillLayout = (props: any) => <FillContainer match={props.match} />

const App = (props: any) => {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/poll/create" element={<NonLandingLayout children={<PollCreationContainer />} />} />
        <Route path="/poll/fill/:id" element={<NonLandingLayout children={<FillLayout />} />} />
        <Route path="/plans" element={<NonLandingLayout children={<Plans />} />} />
        <Route path="/login" element={<NonLandingLayout children={<Login />} />} />
        <Route path="/cookies" element={<NonLandingLayout children={<CookiePolicy />} />} />
        <Route path="/privacy" element={<NonLandingLayout children={<Privacy />} />} />
        <Route path="/about" element={<NonLandingLayout children={<About />} />} />
        <Route path="/" element={<LandingLayout children={<LandingPage />} />} />
        <Route path="/profile" element={<AuthoriseRoute children={<ProfileContainer />} />} />
        <Route path="/dashboard" element={<AuthoriseRoute children={<DashboardContainer />} />} />
        <Route path="/payment" element={<AuthoriseRoute children={<PaymentFormContainer />} />} />
        <Route path="/event/create" element={<AuthoriseRoute children={<EventCreationContainer />} />} />
        <Route path="/event/response/:id" element={<AuthoriseRoute children={<EventResponseContainer />} />} />
      </Routes>
    </BrowserRouter >
  )

}

export default App;
