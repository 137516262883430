import { Box, Flex, Text, Image } from "rebass";
import { IPoll2 } from "../../../../models/poll";
import DescriptionMap from "../../../shared/descriptionMap";

const MobileSummary = ({ kq }: { kq: IPoll2 }) => {
    return <Flex>
        <Box fontFamily="Lato" flex={"0.5 1 auto"} >
            <Text fontFamily="Lato" mb={"0px"} color="grey">You have been invited to:</Text>
            <Text fontFamily="Lato" fontSize={[5]}>{kq.title}</Text>
            <Text fontFamily="Lato" my={2}>{kq.description}</Text>
            <Flex width={"400px"} my={2} >
                <Image
                    width="30px"
                    alignSelf="center"
                    src={require("../../../../images/baseline_location_on_black_18dp.png")}
                    alt="LocationIcon"
                />
                <Box mx="8px" />
                <Text fontFamily="Lato" color="darkslateblue">{kq.location.place_name} </Text>
            </Flex>
        </Box>
        <Box mx={"auto"} />
        <Box width={[1, 0.4]} flex={"1 1 auto"} >
            <DescriptionMap location={kq.location} />
        </Box>
    </Flex>
}
export default MobileSummary