import { Button, Text } from "rebass";
import './buttons.css'
import { Link } from 'react-router-dom';

const NewEventButton = ({ updateStatus }: { updateStatus: () => void }) => {
    return (<Link to={`/event/create`}>
        <Button
            className={'startNewEventButton'}
            bg={'#C20041'}
            width={[1, "160px"]}
            onClick={() => updateStatus()}
        >
            <Text fontSize={2} fontFamily="Lato">
                Create New Event
            </Text>
        </Button>
    </Link>
    )
}

export default NewEventButton;
