import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import "./paymentForm.css";
import { Button, Flex, Box, Text } from "rebass";
import { Link as ReactLink } from 'react-router-dom'
import { Token } from "@stripe/stripe-js";

interface IPaymentForm {
    currentUser: any;
    chargeStatus: string;
    setPaymentProcessingStatus: (status: string) => void;
    createSubscription: (firebase_id: string, token: Token) => void;
}


export const PaymentForm = ({ currentUser, chargeStatus, setPaymentProcessingStatus, createSubscription }: IPaymentForm) => {
    const stripe = useStripe();
    const elements = useElements();

    const submit = async (e: any) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return
        }
        setPaymentProcessingStatus('processing');
        let { token } = await stripe.createToken('pii', currentUser.firebase_id); //Might be wrong
        if (token) {
            createSubscription(currentUser.firebase_id, token)
        }

    };

    return (
        <Box width={[1]} fontFamily="Lato" className="checkout">
            <Text fontFamily="Lato, san-serif">
                You are currently on the <span style={{ fontWeight: 600 }}>free</span> plan. Sign up to the <span style={{ fontWeight: 600 }}>Pro</span> version below for just £1.99 per month
            </Text>
            <Text fontFamily="Lato, san-serif" my={3}>You can cancel at any time through the account screen</Text>

            <Flex width={[1, 500]}>
                <Text
                    alignSelf="center"
                    fontFamily="Lato"
                    fontSize={[1, 2]}
                    width={[100, 180]}
                    color="grey"
                >
                    Card Number
                </Text>
                <Box mx={[0, "auto"]} />
                <Box width={[180, 300]}>
                    <CardNumberElement />
                </Box>
            </Flex>

            <Flex my={2} width={[1, 500]}>
                <Text
                    width={[100, 180]}
                    alignSelf="center"
                    fontFamily="Lato"
                    fontSize={[1, 2]}
                    color="grey"
                >
                    Expiry Date
                </Text>
                <Box mx={[0, "auto"]} />

                <Box width={[180, 300]}>
                    <CardExpiryElement />
                </Box>
            </Flex>

            <Flex my={2} width={[1, 500]}>
                <Text
                    width={[100, 180]}
                    alignSelf="center"
                    fontFamily="Lato"
                    fontSize={[1, 2]}
                    color="grey"
                >
                    CVC
                </Text>
                <Box mx={[0, "auto"]} />
                <Box width={[180, 300]}>
                    <CardCvcElement />
                </Box>
            </Flex>
            <Text fontSize={2} mt={4}>This will authorise Konqur.co to deduct £1.99 per month from your card, starting today. </Text>
            <Flex my={3}>
                <Button bg="#4CDC4C" onClick={submit}>
                    {chargeStatus === "processing" ? "Processing" : "Subscribe"}
                </Button>
                <Box mx="auto" />
                {chargeStatus !== "processing" ? (
                    <ReactLink to={"/profile"}><Button bg="grey" >
                        Cancel
                    </Button></ReactLink>
                ) : null}
            </Flex>
        </Box>
    );
}


export default PaymentForm;