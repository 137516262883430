import { db } from "../components/firebase/firebase";
import { IComment } from "../models/comment";
import { Reducer } from "redux";
import { FIRESTORE_COLLECTIONS } from "./collections";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";

export enum CommentActionTypes {
    GET_COMMENTS = "GET_COMMENTS",
    CREATE_COMMENT = "CREATE_COMMENT",
}

export interface ICommentState {
    readonly comments: IComment[]
}

const initialState:ICommentState = {
    comments: [],
}

export const actionCreators = {
    getComments: (itemType: string, itemId: string) => async (dispatch: any) => {
        const commentCollectionRef = collection(doc(collection(db, itemType), itemId), FIRESTORE_COLLECTIONS.COMMENTS);
        const querySnapshot = await getDocs(commentCollectionRef);
        const comments:IComment[] = [];
            querySnapshot.forEach((doc) => {
                const item = doc.data() as IComment;
                item["id"] = doc.id;
                comments.push(item);
            });
        dispatch({ type: CommentActionTypes.GET_COMMENTS, comments });
    },
    createComment: (itemType: string, itemId: string, comment: IComment) => async (dispatch: any) => {
        const commentCollectionRef = collection(doc(collection(db, itemType), itemId), FIRESTORE_COLLECTIONS.COMMENTS);
        await addDoc(commentCollectionRef, comment)
        dispatch({ type: CommentActionTypes.CREATE_COMMENT });
        dispatch(actionCreators.getComments(itemType, comment.itemId));
    }
}

const commentReducer: Reducer<ICommentState, any> = (state = initialState, action) => {
    switch (action.type) {
        case CommentActionTypes.GET_COMMENTS:
            return {
                ...state,
                comments: action.comments.map((x: IComment) => {
                    return {
                        name: x.name,
                        comment: x.comment,
                        dateCreated: x.dateCreated
                    }
                })
            }
        case CommentActionTypes.CREATE_COMMENT:
            return {
                ...state
            }
        default:
            return state;

    }
}

export default commentReducer;
