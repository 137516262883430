import React from 'react';
import PollFormButtons from './buttons/pollFormButtons'
import Calender from './Calender'
import { Card, Heading, Text, Box } from "rebass";

const PollDatePicker = (props: any) => {
    const [dateRequired, setDateRequired] = React.useState(false);

    const updateStatus = (e: any) => {
        if (props.selectedDates.length > 0) {
            setDateRequired(false);
            props.updateStatus(e);
        }
        else {
            setDateRequired(true);
        }
    }

    return <Card
        width={[1, 1, 3 / 4]}
        p={[3, 5]}
        my={[3]}
        mx="auto"
        bg="#f6f6ff"
    >
        <Text fontFamily="Lato" color="grey">Step 2: </Text>
        <Heading fontFamily="Lato" my={2} color="#6e37ff">Choose some dates</Heading>
        <Calender
            selectedDates={props.selectedDates}
            updateSelectedDates={props.updateSelectedDates}
            calEvents={props.calEvents}
        />

        <Box mt={'20px'}>
            <PollFormButtons
                updateStatus={(e: any) => updateStatus(e)}
                forwardStatus={props.forward}
                backStatus={props.back}
                forwardName={'Next'}
                backName={'Back'}
            />
            <Text
                color={"red"} fontFamily="Lato"
                style={{ float: 'right' }}>
                {dateRequired ? 'Please select at least one date' : ''}
            </Text>
        </Box>
    </Card>
}

export default PollDatePicker;
