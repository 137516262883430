export const MultiLogo = () => { 
     return (
        <svg xmlns="http://www.w3.org/2000/svg"  
            style={{marginBottom: '4px'}} 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            width="27px" 
            height="100%" 
            viewBox="0 0 5200 4800" 
            id="svg25">
        <g className="Group" id="g23">
            
                <g id="id3">
                    <rect className="BoundingBox" x="55" y="1760" width="3061" height="2961" id="rect2" 
                            style={{fill:"none", stroke:"none"}} />
                    <path d="m 1585,1840 c 822,0 1450,606 1450,1400 0,794 -628,1400 -1450,1400 -822,0 -1450,-606 -1450,-1400 0,-794 628,-1400 1450,-1400 z"
                            id="path4"
                        style={{fill:"none",
                            stroke:"#FF8124",
                            strokeWidth:300,
                            strokeLinejoin:"round"}} />
                </g>
         
                <g id="id4">
                    <rect className="BoundingBox" x="2055" y="1760" 
                    width="3061" 
                    height="2961" 
                    id="rect9" style={{fill:"none", stroke:"none"}} />
                    <path d="m 3585,1840 c 822,0 1450,606 1450,1400 0,794 -628,1400 -1450,1400 -822,0 -1450,-606 -1450,-1400 0,-794 628,-1400 1450,-1400 z" id="path11" 
                    style={{fill:"none",
                    stroke:"#FF0115",
                    strokeWidth:300,
                    strokeLinejoin:"round"}} />
                </g>
           
                <g id="id5">
                    <rect className="BoundingBox" x="1055" y="60" width="3061" height="2961" id="rect16" style={{fill:"none", stroke:"none"}} />
                    <path d="m 2585,140 c 822,0 1450,606 1450,1400 0,794 -628,1400 -1450,1400 -822,0 -1450,-606 -1450,-1400 0,-794 628,-1400 1450,-1400 z" id="path18"  style={{fill:"none",
                            stroke:"#C20041",
                            strokeWidth:300,
                            strokeLinejoin:"round"}} />
                </g>
           
        </g>
    </svg>
     )
}

const WhiteLogo = () => 
     <svg xmlns="http://www.w3.org/2000/svg"  
            style={{marginBottom: '4px'}} 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            width="auto%" 
            height="100%" 
            viewBox="0 0 5200 4800" 
            id="svg25">
        <g className="Group" id="g23">
            
                <g id="id3">
                    <rect className="BoundingBox" x="55" y="1760" width="3061" height="2961" id="rect2" 
                            style={{fill:"none", stroke:"none"}} />
                    <path d="m 1585,1840 c 822,0 1450,606 1450,1400 0,794 -628,1400 -1450,1400 -822,0 -1450,-606 -1450,-1400 0,-794 628,-1400 1450,-1400 z"
                            id="path4"
                        style={{fill:"none",
                            stroke:"#ffffff",
                            strokeWidth:300,
                            strokeLinejoin:"round"}} />
                </g>
         
                <g id="id4">
                    <rect className="BoundingBox" x="2055" y="1760" 
                    width="3061" 
                    height="2961" 
                    id="rect9" style={{fill:"none", stroke:"none"}} />
                    <path d="m 3585,1840 c 822,0 1450,606 1450,1400 0,794 -628,1400 -1450,1400 -822,0 -1450,-606 -1450,-1400 0,-794 628,-1400 1450,-1400 z" id="path11" 
                    style={{fill:"none",
                    stroke:"#ffffff",
                    strokeWidth:300,
                    strokeLinejoin:"round"}} />
                </g>
           
                <g id="id5">
                    <rect className="BoundingBox" x="1055" y="60" width="3061" height="2961" id="rect16" style={{fill:"none", stroke:"none"}} />
                    <path d="m 2585,140 c 822,0 1450,606 1450,1400 0,794 -628,1400 -1450,1400 -822,0 -1450,-606 -1450,-1400 0,-794 628,-1400 1450,-1400 z" id="path18"  style={{fill:"none",
                            stroke:"#ffffff",
                            strokeWidth:300,
                            strokeLinejoin:"round"}} />
                </g>
           
        </g>
    </svg>
    
export default WhiteLogo