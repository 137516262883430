import React from 'react';
import mapboxgl, { Marker } from 'mapbox-gl';

const mapBoxToken = 'pk.eyJ1IjoiYmVuY2FsbmFuIiwiYSI6ImNqb28ycXZrazB6eGozcnV1cmNhZG9tOWgifQ.tcez6lWtX5GTfRAA_DBdiA';

const DescriptionMap = ({ location, selectLocation }: any) => {
    let mapNode = React.useRef() as any;
    const [map, setMap] = React.useState<any>(null);
    let marker = React.useRef<Marker | undefined>();

    React.useEffect(() => {
        const init = (id: any) => {
            mapboxgl.accessToken = mapBoxToken;
            let mapBoxMap = new mapboxgl.Map({
                container: id,
                style: 'mapbox://styles/mapbox/streets-v10',
                attributionControl: false
            });
            if (mapBoxMap) {
                setMap(mapBoxMap)
            }
        }
        if (!map) {
            init(mapNode);
        }
    }, [map]);

    React.useEffect(() => {
        if (location.place_type && map) {
            if (marker && marker.current) {
                marker.current.remove()
            }
            const createMarkerandZoomMap = (item: any) => {
                if (item && item.geometry && item.geometry.coordinates.coordinates) {
                    let markerA = new mapboxgl.Marker()
                        .setLngLat(item.geometry.coordinates);
                    marker.current = markerA;
                    markerA.addTo(map)
                }
                switch (item.place_type[0]) {
                    case 'place':
                        map.fitBounds(item.bbox);
                        break;
                    case 'address':
                        map.setCenter(item.center)
                        map.setZoom(15)
                        break;
                    case 'poi':
                        map.setCenter(item.geometry.coordinates)
                        map.setZoom(15)
                        break;
                    default:
                        map.fitBounds(item.bbox)
                        break;
                }
            }
            createMarkerandZoomMap(location)
        }

    }, [location, map, marker]);

    return <div style={{ height: '200px' }} ref={(node) => mapNode = node} id="map" />
}

export default DescriptionMap;