import { Card, Heading, Text, Flex } from "rebass";

export const About =  () => {
    return <div className="container"  > <Flex>
        <Card
            width={1}
            p={[3, 5]}
            my={[3, 5]}
            mx="auto"
            bg="#f6f6ff"
        >
            <Heading fontFamily="Lato" color="#6e37ff">About Us</Heading>
            <Text fontFamily="Lato" my={2} fontSize={[3]}>
                Konqur is the new simple platform to help get things organised.
            </Text>
            <Text fontFamily="Lato" my={2} fontSize={[3]}>
                Created as a result a frustation with existing tools, annoying adverts and unnecessary features,
                Konqur aims to keep things simple and stay out of your way.</Text>
            <Text fontFamily="Lato" my={3} fontSize={[3]}>
                <i>Let's make it happen</i>
            </Text>
            <Text fontFamily="Lato" my={2} fontSize={[1]}>Konqur.co © Copyright 2019. All rights reserved.</Text>
            <Text fontFamily="Lato" my={1} fontSize={[1]}>Version 0.8.6</Text>
        </Card>
    </Flex>
    </div>
}
export default About;