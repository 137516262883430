import { IPoll2 } from "../../../../models/poll";
import moment from 'moment';
import { useEffect, useState } from "react";

const DesktopHeaders = ({ kq }: { kq: IPoll2 }) => {
    const [proMonth, setProMonth] = useState<string[]>([]);
    const [proMonthUnique, setProMonthUnique] = useState<string[]>([]);

    useEffect(() => {
        const items = kq.dates.map((x: any) => moment.unix(x.date.seconds).format("MMMM, YYYY")) // Date of each item
        setProMonth(items)
        setProMonthUnique([...new Set(items)])
    }, [kq])

    return (<tr>
        <th />
        {proMonthUnique.map((x, i) => {
            return <th
                style={{ fontFamily: "Lato" }}
                colSpan={proMonth.filter((y: any) => y === x).length} //Count how many days with this month/year
                scope="col"
                key={`months${i}`}>
                {x}
            </th>
        })}
    </tr >
    )
}

export default DesktopHeaders;