import App from './App';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './store'
import { FireBaseAuth } from "./components/firebase/firebase"
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';

const store = createStore(reducers, applyMiddleware(thunk))
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')

root.render(<Provider store={store}>
    <FireBaseAuth>
        <Elements stripe={stripePromise}>
            <App />
        </Elements>
    </FireBaseAuth>
</Provider>);

