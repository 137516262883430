import { Flex, Text, Image } from 'rebass';
import moment from 'moment'
import calendarIcon from '../../../images/baseline_calendar_today_black_18dp.png'

const EventDate = ({ date }: { date: any }) => {
    return <Flex fontFamily='Lato' fontSize={2} color="darkslateblue">
        <Image
            alignSelf="center"
            height={[18]} width={[18]}
            src={calendarIcon}
            alt="DateIcon"
        />
        <Text ml={1}>{moment(date).format("dddd Mo MMMM YYYY")}</Text>
    </Flex>
}

export default EventDate;