import { Box, Text } from 'rebass';

const EventResponseInfo = (props: any) => {
    let { currentEvent } = props;
    const renderYes = () => {
        if (currentEvent.yes) {
            if (currentEvent.yes.length === 1) {
                return currentEvent.yes.length + " person is going"
            } else if (currentEvent.yes.length > 1) {
                return currentEvent.yes.length + " people are going"
            }
        }

        return ""
    }
    const renderMaybes = () => {
        if (currentEvent.maybe) {
            if (currentEvent.maybe.length === 1) {
                return currentEvent.maybe.length + " person is a maybe"
            } else if (currentEvent.maybe.length > 1) {
                return currentEvent.maybe.length + " people are maybes"
            }
        }
        return ""
    }

    const renderNos = () => {
        if (currentEvent.no) {
            if (currentEvent.no.length === 1) {
                return currentEvent.no.length + " can't make it"
            } else if (currentEvent.no.length > 1) {
                return currentEvent.no.length + " can't make it"
            }
        }
        return ""
    }

    return <Box mt={2}>
        <Text my={1} mr={2} fontFamily="Lato">{renderYes()}</Text>
        <Text my={1} mr={2} fontFamily="Lato">{renderMaybes()}</Text>
        <Text my={1} fontFamily="Lato">{renderNos()}</Text>
    </Box>
}

export default EventResponseInfo;