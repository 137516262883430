
import { combineReducers } from 'redux';
import pollReducer, { IPollState } from './polls';
import authReducer, { IAuthState } from './auth';
import eventReducer, { IEventState } from './events';
import commentReducer, { ICommentState } from './comments';

export interface IAppState {
    cre: IPollState;
    auth: IAuthState;
    env: IEventState;
    com: ICommentState;
}

export default combineReducers({
    cre: pollReducer,
    auth: authReducer,
    env: eventReducer,
    com: commentReducer
});