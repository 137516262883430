import { MultiLogo } from './svg/logo';
import { Flex, Box, Button, Text, Link } from 'rebass';
import { useDispatch, useSelector } from 'react-redux'
import { AuthActionTypes } from '../store/auth';
import { FireBaseAuthContext, IAuthContext } from "./firebase/firebase";
import { IAppState } from '../store';
import { useLocation } from 'react-router-dom';
import { useCallback, useContext } from 'react';

export const Header = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IAppState) => store.auth.currentUser);
    const { signOutAuth } = useContext(FireBaseAuthContext) as IAuthContext;
    let location = useLocation();

    const checkCurrentPath = useCallback((path: any) => {
        if (path === location.pathname) {
            return '#C20041';
        }
        return '#6e37ff';
    }, [location])

    const signOutFromAuthService = () => {
        dispatch({ type: AuthActionTypes.GET_USER, user: null });
        signOutAuth();
    }


    const renderDashboardButton = () => {
        switch (currentUser) {
            case null:
                return;
            case false:
                return;
            default:
                return <li className="nav-item">
                    <a style={{ fontFamily: 'Lato', color: checkCurrentPath("/dashboard") }} className="nav-link" href="/dashboard">Dashboard</a>
                </li>
        }
    }

    const renderProfileButton = () => {
        switch (currentUser) {
            case null:
                return;
            case false:
                return;
            default:
                return <li className="nav-item">
                    <a style={{ fontFamily: 'Lato', color: checkCurrentPath("/profile") }} className="nav-link" href="/profile">Profile</a>
                </li>
        }
    }

    const renderProBadge = () => {
        switch (currentUser) {
            case null:
                return;
            case false:
                return;
            default:
                return currentUser.plan_tier === 'pro'
                    ? <Button style={{ borderRadius: '8px', border: 'solid 1px white' }} mr={3} width={100} bg={'#4CDC4C'}>
                        <Text fontSize={2} fontFamily="Lato">PRO</Text>
                    </Button>
                    : null
        }
    }


    return <nav className="navbar navbar-expand-lg navbar-dark"
        style={{ backgroundColor: 'black' }}>
        <a className="navbar-brand" href="/">
            <Flex mb={1}>
                <Box mr={2} height={'25px'}>
                    <MultiLogo />
                </Box>
                <Text fontFamily='Lato'>Konqur.co</Text>
            </Flex>
        </a>
        <button className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navBarToggleMenu"
            aria-controls="navBarToggleMenu"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>


        <div className="collapse navbar-collapse" id="navBarToggleMenu">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">

                <li className="nav-item">
                    <a style={{ fontFamily: 'Lato', color: checkCurrentPath("/") }} className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                </li>

                {
                    renderDashboardButton()
                }
                {
                    renderProfileButton()
                }
                <li className="nav-item">
                    <a style={{ fontFamily: 'Lato', color: checkCurrentPath("/plans") }} className="nav-link" href="/plans">Plans <span className="sr-only">(current)</span></a>
                </li>
            </ul>
            <ul className="navbar-nav mt-2 mt-lg-0">
                {
                    renderProBadge()
                }
                {
                    currentUser
                        ? <Link onClick={signOutFromAuthService} style={{ fontFamily: 'Lato', color: '#6e37ff', cursor: 'pointer', marginRight: '10px' }} className="navbar-text nav-link" >Logout</Link>
                        : <Link style={{ fontFamily: 'Lato', color: checkCurrentPath("/login"), marginRight: '10px' }} className="navbar-text nav-link" href="/login">Login</Link>
                }
                <li style={{ fontFamily: 'Lato', color: 'white' }} className="navbar-text">
                    Let's make it happen
                </li>
            </ul>

        </div>

    </nav>
}

export default Header;