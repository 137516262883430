import { useMemo, useState } from 'react';
import PollFormButtons from './buttons/pollFormButtons'
import { Card, Heading, Text, Box } from "rebass";
import DescriptionMap from '../../shared/descriptionMap'
import './geocode.css'

interface IPollDescription {
    title: string,
    updateTitle: (title: string) => void;
    description: string;
    updateDescription: (description: string) => void;
    updateStatus: (status: string) => void;
    currentUser: any;
    privateEvent: boolean;
    updateEventPrivacy: (privacy: boolean) => void;
    location: any;
    selectLocation: (location: any) => void;
    updateLocation: (location: any) => void;
    searchResults: any[],
    forwardStatus: string,
    backStatus: string,
}

const PollDescription = ({
    title,
    updateTitle,
    description,
    updateDescription,
    updateLocation,
    updateStatus,
    currentUser,
    privateEvent,
    updateEventPrivacy,
    selectLocation,
    location,
    searchResults, forwardStatus, backStatus }: IPollDescription,
) => {
    const [showSearchQuery, setShowSearchQuery] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [formClass, setFormClass] = useState('needs-validation')


    const updateLocationLocal = (e: any) => {
        setShowSearchQuery(true);
        updateLocation(e)
    }

    const updateStatusLocal = (e: any) => {
        setFormClass('needs-validation was-validated')
        if (e === 'landing') {
            updateStatus(e)
        } else if (title) { //Only allow forward click if title exists. 
            updateStatus(e)
        }
    }

    const renderPrivateEventButton = () => {
        return currentUser && currentUser.plan_tier === 'pro'
            ? <Box my={2} className="custom-control custom-checkbox">
                <input type="checkbox"
                    className="custom-control-input"
                    checked={privateEvent}
                    id="privateEventButton" onChange={() => updateEventPrivacy(!privateEvent)} />
                <label style={{ fontFamily: 'Lato' }} className="custom-control-label" htmlFor="privateEventButton">Restricted Event, only registered users can see and respond</label>
            </Box>
            : null
    }


    const searchHtml = useMemo(() => {
        const selectLocationLocal = (item: any) => {
            setShowSearchQuery(false);
            setShowMap(true)
            selectLocation(item)
        }


        return <ul className='list-group'>
            {searchResults.length > 0
                ? searchResults.map((x: any, i: number) => {
                    return <li
                        className='list-group-item geocodeItem'
                        onClick={() => selectLocationLocal(x)} key={`searchitem_${i}`}>
                        {x.place_name}
                    </li>
                })
                : null
            }
        </ul>
    }, [searchResults, selectLocation])


    return (
        <Card
            width={[1, 1, "700px"]}
            p={[3, 5]}
            my={[3]}
            mx="auto"
            bg="#f6f6ff"
        >
            <Text fontFamily="Lato" color="grey">Step 1: </Text>
            <Heading fontFamily="Lato" my={2} color="#6e37ff">Poll Details</Heading>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <div style={{ flex: '0 1 auto' }}>
                    <form className={formClass} noValidate>
                        {renderPrivateEventButton()}
                        <div className="form-group">
                            <input
                                value={title}
                                style={{ fontFamily: 'Lato' }}
                                onChange={(e: any) => updateTitle(e.target.value)}
                                className="form-control"
                                id="kq_name"
                                type="text"
                                required
                                placeholder="Name of Event*" />
                            <div className="invalid-feedback">
                                <Text fontFamily="Lato" my={2} fontSize={[2]}>
                                    Please choose an event title!
                                </Text>
                            </div>

                        </div>
                        <div className="form-group">
                            <textarea
                                value={description}
                                style={{ fontFamily: 'Lato' }}
                                onChange={(e: any) => updateDescription(e.target.value)}
                                className="form-control"
                                id="konqurDescription"
                                rows={2}
                                placeholder="Description (optional)" />
                        </div>
                        <div className="form-group">
                            <input
                                value={location.place_name ?? ''}
                                onChange={(e) => updateLocationLocal(e)}
                                className="form-control"
                                style={{ fontFamily: 'Lato' }}
                                id="kq_location"
                                type="text"
                                placeholder="Where's it happening? (optional)" />
                            {searchResults.length > 0 && showSearchQuery
                                ? searchHtml
                                : null
                            }
                        </div>
                        {showMap
                            ? <DescriptionMap location={location} />
                            : null
                        }

                    </form>
                </div>
                <div style={{ flex: '1 1 auto', marginTop: '20px' }}>
                    <PollFormButtons
                        updateStatus={(e: any) => updateStatusLocal(e)}
                        forwardStatus={forwardStatus}
                        backStatus={backStatus}
                        forwardName={'Next'}
                        backName={'Back'}
                    />
                </div>
            </div>

        </Card>

    );
}











export default PollDescription;