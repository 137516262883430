import { Button, Box } from "rebass";
import './buttons.css';

interface IPollFormButtons {
    updateStatus: (status: string) => void,
    backName: string,
    forwardName: string,
    backStatus: string,
    forwardStatus: string
}

const PollFormButtons = (
    { updateStatus, backName, forwardName, backStatus, forwardStatus }: IPollFormButtons) => {
    return (<Box>
        <Button
            mt="10px"
            mr="10px"
            fontWeight="normal"
            fontFamily="Lato"
            className="backButton"
            onClick={() => updateStatus(backStatus)} bg="#6c757d">
            {backName}
        </Button>
        <Button
            mt="10px"
            fontWeight="normal"
            fontFamily="Lato"
            className="forwardButton"
            onClick={() => updateStatus(forwardStatus)}
            onKeyPress={event => {
                if (event.key === 'Enter') {
                    updateStatus(forwardStatus)
                }
            }}

            bg="#4CDC4C">
            {forwardName}
        </Button>
    </Box>
    )
}
export default PollFormButtons
