import { useContext, useEffect } from 'react';
import { Card, Button, Heading, Image, Text, Box, Flex } from "rebass";
import { useSelector } from 'react-redux';
import { FireBaseAuthContext, IAuthContext } from "../firebase/firebase";
import { IAppState } from '../../store';
import { useNavigate } from 'react-router-dom';

const LoginComponent = () => {
    const { signInAuth } = useContext(FireBaseAuthContext) as IAuthContext;
    const currentUser = useSelector((store: IAppState) => store.auth.currentUser)
    const navigate = useNavigate();

    const login = async (e: any) => {
        try {
            await signInAuth();
        } catch (error) {
            console.log("error", error)
        }
    };

    useEffect(() => {
        if (currentUser) { //Use Current User not authUser so is ready before navigation.
            navigate('/dashboard');
        }
    }, [currentUser, navigate])


    return <Box>
        <Flex alignSelf="center">
            <Card
                style={{ borderRadius: '4px' }}
                width={[1, '380px']}
                p={[3, 5]}
                my={[3, 5]}
                mx={[3, "auto"]}
                bg="floralwhite"
                // boxShadow="0 2px 16px rgba(0, 0, 0, 0.25)"
            >
                <Box style={{ textAlign: "center" }} mb={3}>
                    <Image alignSelf="center" width={['60px']} src={require("../../images/konqur-logo.png")} />
                    <Heading fontFamily="Lato" mb={2}  >
                        Welcome Back
                    </Heading>
                    <Heading fontFamily="Lato" >
                        Login to continue
                </Heading>
                </Box>
                <Flex>
                    <Box mx='auto' />
                    <Button onClick={(e) => login(e)} style={{ textAlign: "center" }}>
                        <img
                            src={require("../../images/googleoauth/btn_google_signin_dark_normal_web.png")}
                            alt="GoogleSignIn"
                        />
                    </Button>
                    <Box mx='auto' />
                </Flex>
                <Text style={{ "fontFamily": "Lato" }} mt={4} mb={2} fontSize={'10px'}>
                    We use Google authentication APIs to allow users to save and edit their events.
                    Events and user accounts can be deleted at any time.
                </Text>

            </Card>
            {/* <Text style={{ "fontFamily": "Lato" }} my={3} fontSize={'10px'}>
                    We use calendar apis to help users identify good days to organise events.
                    We do not store any of this data in our systems.
                </Text> */}
        </Flex>
    </Box>
}

export default LoginComponent;

