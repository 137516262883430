import { useState, useRef } from 'react';
import PollHomeButton from './buttons/pollHomeButton'
import ShareButtons from './buttons/shareButtons';
import { Card, Heading, Text, Box } from "rebass";

const PollSummary = (props: any) => {
    let url = props.url ? window.location.href.substring(0, window.location.href.length - 6) + 'fill/' + props.url : '';

    const [clipBoardCopy, setClipBoarCopy] = useState(false);

    const urlRef = useRef() as any;

    const copyURLToClipBoard = () => {
        urlRef.current.select();
        document.execCommand("copy");
        setClipBoarCopy(true);
    }

    return (
        <Card
            width={[1, 1, "700px"]}
            p={[3, 5]}
            my={[3]}
            mx="auto"
            bg="#f6f6ff"
        >
            <Text fontFamily="Lato" color="grey">Step 4: </Text>
            <Heading fontFamily="Lato" color="#6e37ff">Share</Heading>
            <Text fontFamily="Lato" my={1} fontSize={[3]}>
                Great, you've created some options
            </Text>

            <Text fontFamily="Lato" my={1} fontSize={[3]}>
                Now lets get some others on board
            </Text>
            <Box mt={20}>
                <div className="input-group">
                    <input
                        value={url}
                        className="form-control"
                        id="kq_link"
                        type="text"
                        ref={urlRef}
                        readOnly
                        placeholder="Name" />
                    <div className="input-group-append">
                        <span style={{ cursor: 'pointer' }}
                            className="input-group-text" onClick={() => copyURLToClipBoard()}
                            id="srcNode">Copy</span>
                    </div>
                </div>
            </Box>

            <Box style={{ height: '20px' }} mt={10}>
                {
                    clipBoardCopy
                        ? <div style={{ fontSize: 'small', textAlign: 'right', color: '#0078fa' }}>URL copied to clipboard</div>
                        : null
                }
            </Box>
            <Box style={{ height: '20px' }}>
                <a href={url}>Take me there!</a>
            </Box>
            <Box mt={20}>
                <ShareButtons
                    title={props.title}
                    url={url}
                    itemType="poll"
                />
            </Box>
            <Box mt={20}>
                <PollHomeButton updateStatus={props.updateStatus} />
            </Box>
        </Card>
    )
}
export default PollSummary;