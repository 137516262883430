import { Link } from 'react-router-dom'
import './landingContent.css'
import MultiLogo from "../../../images/konqur-logo.png"

import {
    Heading, Image,
    Box, Flex, Button
} from 'rebass'

const HeroContent = () => {
    return <Box className="heroContainer">
        <Box pt={[3, 3, 5]} className="landingPageBackgroundImage">
            <Flex m={[3, 3, 5]} alignItems="center" >
                <Box>
                    <Image src={MultiLogo} />
                </Box>
                <Box>
                    <Heading
                        color={"white"}
                        fontFamily="Lato"
                        fontSize={["24px", "30px", "42px"]}
                        letterSpacing={"0.01em"}
                        fontWeight={600}>
                        Konqur makes organising events easy </Heading>
                    <Heading color={"#C20041"}
                        fontFamily="Lato"
                        fontSize={["24px", "30px", "42px"]}
                        letterSpacing={"0.01em"}
                        mt={15}
                        fontWeight={600}>
                        Let's make it happen
                    </Heading>
                </Box>
            </Flex>
            <Flex mt={4} ml={[3, 3, 5]} >
                <Box>
                    <Link to={"/poll/create"}>
                        <Button bg="#4CDC4C" fontFamily="Lato">
                            Create Poll
                        </Button>
                    </Link>
                </Box>
                <Box ml={2}>
                    <Link to={"/event/create"}>
                        <Button bg="#C20041" fontFamily="Lato">
                            Create Event
                        </Button>
                    </Link>
                </Box>
            </Flex>
        </Box>
    </Box>
}

export default HeroContent;