import { Box, Flex, Text, Image } from 'rebass';
import LocationIcon from "../../../images/baseline_location_on_black_18dp.png"

const EventLocation = ({ location }: { location: any }) => {
    return <Box fontFamily='Lato' >
        <Flex color="darkslateblue">
            <Box flex="1 1 auto">
                <Image
                    height={18}
                    src={LocationIcon}
                    alt="LocationIcon"
                />
            </Box>
            <Text ml={1}>{location.place_name}</Text>

        </Flex>
    </Box>
}

export default EventLocation;