import { useState, useEffect, ChangeEventHandler, useMemo } from 'react';
import moment from "moment";
import { Box, Flex, Text } from "rebass";
import { IPoll2, IPollUserResponse } from '../../../../models/poll';
import { Timestamp } from 'firebase/firestore';
import DesktopSummary from './desktopSummary';
import DesktopMonthHeaders from './desktopMonthHeaders';

interface IDateTableDesktop {
    kq: IPoll2,
    formClass: string,
    alreadyFilledOut: boolean,
    updateResponse: (date: Timestamp) => void,
    userResponse: IPollUserResponse | undefined,
    updateUserName: ChangeEventHandler<HTMLInputElement>,
}

const DateTableDesktop = ({ kq, formClass, alreadyFilledOut, updateResponse, userResponse, updateUserName }: IDateTableDesktop) => {
    //Array of indexes of dates from earliest to latest  
    const [mostPopular, setMostPopular] = useState<any[]>([]);

    useEffect(() => {
        let mostNumberPicked = 0;
        let mostPopular: any[] = []
        if (kq && kq.dates) {
            kq.dates.forEach((x: any, i: number) => {
                if (x.responses.length === mostNumberPicked && x.responses.length > 1) {
                    mostPopular.push(i) //If same as most number add new index to array
                }
                else if (x.responses.length > mostNumberPicked && x.responses.length > 1) {
                    mostNumberPicked = x.responses.length //If higher than current then set new highest and create new array.
                    mostPopular = [i]
                }
            })
            setMostPopular(mostPopular)

        }
    }, [kq])

    const checkIfMostPicked = (i: any) => {
        if (mostPopular.includes(i)) {
            return true
        }
        return false
    }


    const createDayHeaders = () => {
        return kq.dates.map((x: any, i: number) => {
            return <th
                scope="col"
                style={{ minWidth: "100px", fontFamily: "Lato" }}
                key={`availableDate_${i}`}>
                { }
                <Box>
                    {moment.unix(x.date.seconds).format("ddd Do")}
                </Box>
                <Flex>
                    {checkIfMostPicked(i)
                        ? <Text fontWeight="bold" textAlign="center" fontFamily="Lato" ml={2}> <span style={{ marginLeft: '4px' }} role="img" aria-label="fire">🔥</span></Text>
                        : null}
                    {createExistingCalendarAlert(x)}
                </Flex>
            </th>
        })
    }
    const createExistingCalendarAlert = (day: any) => {
        if (day.calendar.length > 0) {
            return <Text fontWeight="bold" textAlign="center" fontFamily="Lato" width={25} ml={3}>
                <span role="img" aria-label="bell">🔔</span>
            </Text>
        }
        return
    }

    const createExistingResponseRows = () => {
        return kq.respondees.map((name: string, i: number) => {
            return <tr key={`existingReponses_${i}`}>
                <td>{name}</td>
                {kq.dates.map((y: any) => {
                    return (
                        <td key={`actualResponse_${y.date}`}>
                            <input
                                style={{ marginLeft: "20px", marginTop: "10px" }}
                                type="checkbox"
                                checked={y.responses.includes(name)}
                                readOnly={true}
                            />
                        </td>
                    );
                })}
            </tr>
        })
    }


    const createNewResponseRow = useMemo(() => {
        const checkIfChecked = (userResponse: IPollUserResponse, existingUserResponses: {
            responses: string[];
            date: Timestamp;
            calendar: string[];
        }) => {
            const correctItem = userResponse.responses.find((x) => x.date.seconds === existingUserResponses.date.seconds);
            if (correctItem && correctItem.response) {
                return true
            }
            return false;
        }
        return <tr>
            <td>
                <form className={formClass} noValidate>
                    <div className="form-group">
                        <input
                            value={userResponse ? userResponse.name : ''}
                            onChange={updateUserName}
                            className="form-control"
                            style={{ fontFamily: "Lato", maxWidth: '400px' }}
                            required
                            disabled={alreadyFilledOut}
                            id="resp_name"
                            type="text"
                            placeholder="Your name"
                        />
                        <div className="invalid-feedback">
                            <Text fontFamily="Lato" my={2} fontSize={[2]}>
                                Add your name!
                            </Text>
                        </div>
                    </div>
                </form>
            </td>

            {kq.dates.map((z) => {
                return <td key={"k_new_response" + z.date}>
                    {userResponse && userResponse.responses.length > 0 ? (
                        <input
                            style={{ marginLeft: "20px", marginTop: "10px" }}
                            type="checkbox"
                            checked={checkIfChecked(userResponse, z)}
                            disabled={alreadyFilledOut}
                            onChange={() => updateResponse(z.date)}
                        />
                    ) : null}
                </td>

            })}
        </tr>
    }, [formClass, userResponse, alreadyFilledOut, kq.dates, updateResponse, updateUserName])


    const renderTable = () => {
        return <table className="table">
            <tbody>
               
                    
                    {kq && (<DesktopMonthHeaders kq={kq} />)}
               
                <tr>
                    <th scope="col" style={{ minWidth: "300px", fontFamily: "Lato" }}>Name</th>
                    {createDayHeaders()}
                </tr>
                {createExistingResponseRows()}
                {createNewResponseRow}
            </tbody>
        </table>
    }

    return <div>
        {kq && <DesktopSummary kq={kq} />}
        <Text fontSize={[3]} fontFamily={"Lato"}>Select the dates you can make and hit submit</Text>
        <Box mt={"20px"} style={{ overflowX: "scroll" }}>
            {kq && kq.dates ? renderTable() : null}
        </Box>
    </div>

}

export default DateTableDesktop;
