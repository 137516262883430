import { Flex, Box } from 'rebass';
import './plans.css'

export const plans = () => {
    return <Flex fontFamily="Lato" my={5} className="pricing">
        <Box mx='auto' px={"15px"} style={{maxWidth: "1140px"}} width={1} >
            <Flex className="row">
                <Box ml={'auto'} className="col-lg-4">
                    <div className="card mb-5 mb-lg-0">
                        <div className="card-body">
                            <h5 className="card-title text-muted text-uppercase text-center">Free</h5>
                            <h6 className="card-price text-center">£0<span className="period">/month</span></h6>
                            <hr />
                            <ul className="fa-ul">
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited public polls & events</li>
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Dashboards - Keep tabs on all your responses </li>
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Google Calendar integration</li>
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Anonymous polls</li>
                                <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Email notifications</li>
                                <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Comments</li>
                            </ul>
                            <span ref="#" className="btn btn-block btn-primary">FREE</span>
                        </div>
                    </div>
                </Box>
                <Box mr={'auto'} className="col-lg-4">
                    <div className="card mb-5 mb-lg-0">
                        <div className="card-body">
                            <h5 className="card-title text-muted text-uppercase text-center">Pro</h5>
                            <h6 className="card-price text-center">£1.99<span className="period">/month</span></h6>
                            <hr />
                            <ul className="fa-ul">
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Private Polls & Events</li>
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Microsoft Office 365 and Google GSuite Support</li>
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Enhanced Dashboard</li>
                                <li><span className="fa-li"><i className="fas fa-check"></i></span>24/7 Support</li>
                                <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Mobile notifications</li>
                                <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>+ all the features of the free version</li> 
                            </ul>
                            <span style={{background:'#FF8124', border: 'solid 1px #FF8124'}} className="btn btn-disabled btn-block btn-primary ">COMING SOON</span>
                        </div>
                    </div>
                </Box>
            </Flex>
        </Box>
    </Flex>
}

export default plans;