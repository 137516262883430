import { useState } from 'react';
import { Flex, Box } from "rebass";
import Header from './components/header'
import CookiePopup from './components/privacy/cookiePopup';
import ToastNotification from './components/notifications/ToastNotification';

export const LandingLayout = (props: any) => {
    const [homePage, setHomePage] = useState(window.location.pathname === "/" ? true : false);
    return <Flex className="back" flexDirection="column">
        <Header/>
        <Flex flexDirection="column" flex="1 1 auto" style={{ overflowY: 'auto', width:'100%' }}>
            {props.children}
            {homePage
                ? <Box style={{ position: "fixed", bottom: 0, width: '100%' }}>
                    <Flex>
                        <Box mx='auto' />
                        <CookiePopup close={() => setHomePage(false)} />
                        <Box mx='auto' />
                    </Flex>
                </Box>
                : null}


        </Flex>
        <ToastNotification />
    </Flex>
}

export default LandingLayout;
