import Footer from '../footer'
import Helmet from 'react-helmet';
import PollContent from './content/pollContent';
import SecondaryContent from './content/secondaryContent';
import HeroContent from './content/heroContent';
import EventContent from './content/eventContent';
import ContentLayout from './content/contentLayout';

const LandingPage = () => {
    return <div style={{ height: '100%', width: '100%' }}  >
        <Helmet title="Konqur - Event Scheduling" />
        <div style={{ backgroundImage: `linear-gradient(to right, rgb(110, 55, 255), rgb(182, 156, 248))` }}>
            <HeroContent />
            <div>
                <ContentLayout>
                    <PollContent />
                </ContentLayout>
                <ContentLayout>
                    <EventContent />
                </ContentLayout>
                <ContentLayout>
                    <SecondaryContent />
                </ContentLayout>
            </div>
            <Footer />
        </div>
    </div>
};

export default LandingPage;



