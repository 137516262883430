import { Flex, Box, Button } from 'rebass';

const EventSignup = ({ currentEvent, currentUser, updateStatus }: { currentEvent: any, currentUser: any, updateStatus: (status: string) => void }) => {
    let { yes, maybe, no } = currentEvent;
    let { id } = currentUser || "";

    const checkStatus = (responseCollection: any, currentUserId: string) => {
        if (responseCollection) {
            return responseCollection.includes(currentUserId);
        }
        return false
    }

    return <Box fontFamily='Lato' >

        {/* If registerd, fine, (Get status) if not, then require name */}
        <Flex mt={2}>
            <Button
                bg={checkStatus(yes, id) ? "#24b824" : "#4CDC4C"}
                style={{ border: checkStatus(yes, id) ? "solid 3px #4CDC4C" : "none" }}
                onClick={() => updateStatus('yes')}>Going</Button>
            <Button ml={2}
                style={{ border: checkStatus(maybe, id) ? "solid 3px #FF8124" : "none" }}
                bg={checkStatus(maybe, id) ? "#d75b00" : "#FF8124"} onClick={() => updateStatus('maybe')}>Not Sure Yet</Button>
            <Button ml={2}
                style={{ border: checkStatus(no, id) ? "solid 3px #FF0115" : "none" }}
                bg={checkStatus(no, id) ? "#b4000e" : "#FF0115"} onClick={() => updateStatus('no')}>Can't Make It</Button>
        </Flex>
    </Box>
}

export default EventSignup;