import React, { useState } from 'react';
import { Box, Flex, Button } from 'rebass';
import { Form, FormControl } from 'react-bootstrap';
import deepcopy from 'deepcopy';

const NewComment = ({ currentUser, submitComment, closeCommentBox }: { currentUser: any, submitComment: any, closeCommentBox: () => void }) => {
    const [name, setName] = useState("");
    const [userId, setUserId] = useState("");

    const [comment, setComment] = useState("");
    const [disabledName, setDisabledName] = useState(false);

    const submitCopyOfComment = (comment: { name: string, comment: string, userId: string }) => {
        const commentCopy = deepcopy(comment)
        setName('');
        setComment('');
        submitComment(commentCopy)
    }

    React.useEffect(() => {
        if (currentUser && currentUser.name) {
            setName(currentUser.name)
            setUserId(currentUser.id)
            setDisabledName(true)
        }
        else {
            setDisabledName(false)
        }

    }, [currentUser])

    return <Box fontFamily="Lato" fontSize={[1]}>
        <Box width={[1, 0.5]}>
            <Form.Group style={{ marginBottom: '2px' }}>
                <Form.Label>Name</Form.Label>
                <FormControl type="text" placeholder={"Enter your name"} value={name} disabled={disabledName} aria-label="Name" onChange={(e => setName(e.target.value))} />
            </Form.Group>
            <Form.Group style={{ marginBottom: '0px' }}>
                <Form.Label>Comment</Form.Label>
                <FormControl as="textarea" placeholder={"Enter your comment here"} value={comment} aria-label="Comment" onChange={(e => setComment(e.target.value))} />
            </Form.Group>
            <Flex>
                <Button mt={2} bg={'rgb(110, 55, 255)'} color='white' onClick={() => submitCopyOfComment({ name, comment, userId })}>Submit</Button>
                <Box mx='auto' />
                <Button mt={2} bg={'grey'} color='white' onClick={closeCommentBox}>Close</Button>
            </Flex>
        </Box>
    </Box>
}

export default NewComment;