import React from 'react';
import { Card, Flex, Text, Heading, Box, Button } from 'rebass';
// import { Label, Checkbox } from '@rebass/forms';
import DescriptionMap from "../../shared/descriptionMap";
import { useDispatch } from "react-redux";
import { createEvent } from "../../../store/events";
import { FireBaseAuthContext, IAuthContext } from "../../firebase/firebase";
import { getFireBaseTimeStamp } from '../../firebase/utils';
import { IMapBoxGeoInfo } from '../../../models/event';
import { useNavigate } from 'react-router-dom';
// Prepopulate if coming from poll
// Route from dashboard vanilla
// Route from Landing Vanilla
// Private? 
// Image

const EventCreationContainer = () => {
    const navigate = useNavigate();

    const [eventTitle, setEventTitle] = React.useState("");
    const [eventDescription, setEventDescription] = React.useState("")
    const [eventLocation, setEventLocation] = React.useState<IMapBoxGeoInfo | undefined>() // Change to PROPER LOCATION
    const [eventDate, setEventDate] = React.useState("");
    // const [requireSignIn, setRequireSignIn] = React.useState(false);
    const [showMap, setShowMap] = React.useState(false);
    const [showSearchQuery, setShowSearchQuery] = React.useState(false);
    const [locationSearchResults, setLocationSearchResults] = React.useState([]);
    const { authUser } = React.useContext(FireBaseAuthContext) as IAuthContext;

    const dispatch = useDispatch();
    
    const selectLocation = (item: any) => {
        setShowMap(true);
        setShowSearchQuery(false);
        setEventLocation(item);

    }

    const updateLocation = (e: any) => {
        setShowSearchQuery(true);
        setEventLocation(e.target.value)
        let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
        url += e.target.value
        url += '.json'
        url += '?access_token=pk.eyJ1IjoiYmVuY2FsbmFuIiwiYSI6ImNqb28ycXZrazB6eGozcnV1cmNhZG9tOWgifQ.tcez6lWtX5GTfRAA_DBdiA'
        url += '&autocomplete=true'

        fetch(url)
            .then(response => response.json())
            .then(results => {
                results.features
                    ? setLocationSearchResults(results.features)
                    : setLocationSearchResults([])
            })
    }

    const renderSearchResults = () => {
        return <ul className='list-group'>
            {locationSearchResults.length > 0
                ? locationSearchResults.map((x: any, i) => {
                    return <li
                        className='list-group-item geocodeItem'
                        onClick={() => selectLocation(x)} key={`searchitem_${i}`}>
                        {x.place_name}
                    </li>
                })
                : null
            }
        </ul>
    }

    const publishEvent = async () => {
        const newEvent = {
            eventTitle,
            eventDescription,
            eventLocation, // This is wrong
            eventDate,
            yes: [],
            maybe: [],
            no: [],
            user: authUser?.uid as string,
            requireSignIn: true,
            dateCreated: getFireBaseTimeStamp(),
            lastUpdated: getFireBaseTimeStamp(),
        }
        await dispatch(createEvent(newEvent, navigate));
    }

    const _onFocus = (e: any) => e.currentTarget.type = "date";

    const _onBlur = (e: any) => {
        if (!e.target.value) {
            e.currentTarget.type = "text";
            e.currentTarget.placeholder = "Date of Event*";
        }
    };

    const goBack = () => {
        navigate(-2) // SHould be -1 Not sure why this is happening. 
    }

    return <Card
        width={[1, 1, "700px"]}
        p={[3, 5]}
        my={[3]}
        mx="auto"
        bg="#f6f6ff"
    >
        <Text fontFamily="Lato" color="grey">Create Event:</Text>
        <Heading fontFamily="Lato" my={2} color="#6e37ff">Event Details</Heading>
        <Flex flexDirection='column' >
            <div style={{ flex: '0 1 auto' }}>
                <form noValidate>
                    <Box my={2} className="form-group">
                        <input
                            value={eventTitle}
                            style={{ fontFamily: 'Lato' }}
                            onChange={(e) => setEventTitle(e.target.value)}
                            className="form-control"
                            id="kq_name"
                            type="text"
                            required
                            placeholder="Name of Event*" />
                        <div className="invalid-feedback">
                            <Text fontFamily="Lato" my={2} fontSize={[2]}>
                                Please choose an event title!
                            </Text>
                        </div>
                    </Box>
                    <Box my={2} className="form-group">
                        <input
                            value={eventDate}
                            style={{ fontFamily: 'Lato' }}
                            onChange={(e) => setEventDate(e.target.value)}
                            className="form-control"
                            id="event_date"
                            type="text"
                            onFocus={_onFocus}
                            onBlur={_onBlur}
                            required
                            placeholder="Date of Event*" />
                        <div className="invalid-feedback">

                        </div>
                    </Box>
                    <Box my={2} className="form-group">
                        <textarea
                            value={eventDescription}
                            style={{ fontFamily: 'Lato' }}
                            onChange={(e) => setEventDescription(e.target.value)}
                            className="form-control"
                            id="event_description"
                            rows={3}
                            placeholder="Description" />
                    </Box>
                    <Box className="form-group">
                        <input
                            value={eventLocation ? eventLocation.place_name : ""}
                            onChange={(e) => updateLocation(e)}
                            className="form-control"
                            style={{ fontFamily: 'Lato' }}
                            id="kq_location"
                            type="text"
                            placeholder="Where's it happening? (optional)" />
                        {locationSearchResults.length > 0 && showSearchQuery
                            ? renderSearchResults()
                            : null
                        }
                    </Box>
                    <Box my={2}>
                        {showMap
                            ? <DescriptionMap location={eventLocation} />
                            : null
                        }
                    </Box>
                    {/* <Box my={2} className="form-group">
                        <Label fontFamily="Lato">
                            <Checkbox
                                id='remember'
                                name='remember'
                                checked={requireSignIn}
                                onChange={() => setRequireSignIn(!requireSignIn)}
                            />
                            Require Sign In
                        </Label>

                    </Box> */}
                </form>
            </div>
        </Flex>
        <Flex mt={2}>
            {/* <Link href="/dashboard"> */}
                <Button onClick={goBack} fontWeight="normal"
                    fontFamily="Lato" bg="#6c757d">Cancel</Button>
            {/* </Link> */}
            <Box mx="auto" />
            <Button fontWeight="normal"
                fontFamily="Lato" bg="#4CDC4C" onClick={publishEvent}>Publish</Button>
        </Flex>
    </Card>
}

export default EventCreationContainer;