import { Box, Button, Text } from "rebass";
import './button.css';

const SubmitButton = ({ alreadyFilledOut, submitResponse }: { alreadyFilledOut: boolean, submitResponse: () => void }) => {
    return <Box py={2} width={"100px"} mr={2}>
        <Button
            className={!alreadyFilledOut ? 'submitButton' : undefined}
            onClick={!alreadyFilledOut ? () => submitResponse() : () => null}
            fontWeight="normal"
            fontFamily="Lato"
            bg={!alreadyFilledOut ? '#4CDC4C' : 'grey'}>
            <Text fontFamily="Lato">
                {!alreadyFilledOut ? 'Submit' : 'Submitted'}
            </Text>
        </Button>
    </Box>
}

export default SubmitButton;
