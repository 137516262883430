import { Box, Card, Heading, Text, Flex } from "rebass";
import moment from "moment";
import NameInput from './nameInput';
import SummaryCard from './summaryCard';
import { IPoll2, IPollUserResponse } from "../../../../models/poll";
import { ChangeEventHandler } from "react";
import { Timestamp } from "firebase/firestore";

interface IDateTableMobile {
    kq: IPoll2,
    alreadyFilledOut: boolean,
    updateResponse: (date: Timestamp) => void,
    userResponse: IPollUserResponse | undefined,
    updateUserName: ChangeEventHandler<HTMLInputElement>,
    mostNumberPicked: number,
    children: any,
}

const dateTableMobile = ({ kq, alreadyFilledOut, updateResponse, userResponse, updateUserName, mostNumberPicked, children }: IDateTableMobile) => {

    const createMonthHeader = (m: string, l: number) => {
        let colours = ["#C20041", "#FF0115", "#FF8124", "#6e37ff", "#22aa22"];
        return <Card bg={colours[(l - 1) % colours.length]} py={10}>
            <Heading
                textAlign="center"
                fontSize={[3]}
                fontFamily="Lato"
                color="white">
                {m}
            </Heading>
        </Card>
    }

    //Works out the current days responses including the users click.
    const addCurrentTicked = (dateInQuestion: Timestamp, currentNumberOfResponses: number) => {
        if (userResponse && userResponse.responses && userResponse.responses.length > 0) {
            let correctItem = userResponse.responses.find((u) => u.date.seconds === dateInQuestion.seconds);
            if (correctItem && correctItem.response === true) {
                return currentNumberOfResponses + 1
            }

        }
        return currentNumberOfResponses
    }

    const createInputBox = (dateInQuestion: Timestamp) => {
        let correctItem = userResponse ? userResponse.responses.find((u: any) => u.date.seconds === dateInQuestion.seconds) : undefined;
        return <input style={{ marginTop: '6px' }}
            checked={correctItem ? correctItem.response : false}
            type="checkbox"
            disabled={alreadyFilledOut}
            onChange={() => updateResponse(dateInQuestion)}
        />
    }

    const createHotness = (currentNumRespondees: number) => {
        if (mostNumberPicked === currentNumRespondees && mostNumberPicked > 1) {
            return <Text fontWeight="bold" textAlign="center" fontFamily="Lato" ml={2}>
                <span role="img" aria-label="fire">🔥</span>
            </Text>
        }
        return
    }


    const createExistingCalendarAlert = (day: any) => {
        if (day.calendar.length > 0) {
            return <Text fontWeight="bold" textAlign="center" fontFamily="Lato" ml={2}>
                <span role="img" aria-label="bell">🔔</span>
            </Text>
        }
        return
    }

    const createSingleDayBlock = (d: {
        responses: string[];
        date: Timestamp;
        calendar: string[];
    }, i: number) => {
        // let formattedDate = moment(d.date).format("DDMMYYYY");
        let localTicked = 0;
        let inputBox;
        if (userResponse && userResponse.responses.length > 0) {
            localTicked = d.responses ? addCurrentTicked(d.date, d.responses.length) : 0
            inputBox = createInputBox(d.date);
        }

        //Modify so also includes localticked (doese't work because comparing against most. )
        let hotness = createHotness(d.responses.length)
        let existingCalendarEvent = createExistingCalendarAlert(d)
        return <Card bg={i % 2 === 0 ? "white" : "lightgray"} key={"option" + d.date}>
            <Flex p={3} width={[1]}>
                <Box alignSelf="center" width={[1 / 3]}>
                    <Flex>
                        <Text fontFamily="Lato"> {moment.unix(d.date.seconds).format("ddd Do")}</Text>
                        {existingCalendarEvent}
                    </Flex>
                </Box>
                <Text textAlign="center" alignSelf="center" width={[1 / 3]}>
                    {inputBox}
                </Text>
                <Box mx={"auto"} />
                <Box alignSelf="center" width={[1 / 4]}>
                    <Flex>
                        <Text
                            fontWeight="bold"
                            textAlign="center"
                            fontFamily="Lato"
                            color={localTicked > 0 ? "green" : "grey"}>
                            &#x2714;{" "}
                            {localTicked}
                        </Text>
                        {hotness}
                    </Flex>
                </Box>
            </Flex>
        </Card>
    }

    const renderAvailableDates = () => {
        let availDates: any[] = [];
        //For Each month create a header, then a series of day blocks.
        let proMonth = kq.dates.map((x: any) => moment.unix(x.date.seconds).format("MMMM, YYYY")) // Date of each item
        let proMonthUnique = [...new Set(proMonth)]; //Unique Month/YY
        let i = 0;
        proMonthUnique.forEach((m, l) => {
            l++
            let monthHeaderBlock = createMonthHeader(m as any, l)
            let monthDaysBlock: any[] = []

            let filteredDays = kq.dates.filter((x: any) => moment.unix(x.date.seconds).format("MMMM, YYYY") === m)
            filteredDays.forEach((d) => {
                let singleDay = createSingleDayBlock(d, i);
                monthDaysBlock.push(singleDay);
                i++
            })
            availDates.push(
                <div key={"monthGroup:" + l}>
                    {monthHeaderBlock}
                    {monthDaysBlock}
                </div>
            );
        })
        return availDates
    }




    return <Box width={[1]} >
        {kq && (<SummaryCard kq={kq} />)}
        <Card
            mt={2}
            bg={"white"}
        >
            <NameInput
                userResponse={userResponse}
                updateUserName={updateUserName}
                alreadyFilledOut={alreadyFilledOut}
            />
            {kq && kq.dates ? renderAvailableDates() : null}
            <Flex>
                <Box ml={1}>{children}</Box>
                <Box mx='auto' />
                <Text fontSize={1} p={2} fontFamily='Lato' alignSelf="center"><span role="img" aria-label="bell">🔔</span> = Existing Events in your Calender</Text>
            </Flex>
        </Card>
    </Box >
}

export default dateTableMobile;