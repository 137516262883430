
import { Fragment, useContext, useEffect } from 'react';
import { redirect } from 'react-router-dom';
import NonLandingLayout from '../NonLandingLayout';
import { FireBaseAuthContext, IAuthContext } from "./firebase/firebase";

export const AuthoriseRoute = (props: any) => {
    const { authUser, ready } = useContext(FireBaseAuthContext) as IAuthContext;

    useEffect(() => {
        if (ready) {
            if (!authUser) {
                redirect("/home")
            }
        }
    }, [ready, authUser])


    if (!ready) {
        return <div />
    } else {
        return (<Fragment>
            {authUser && (
                <NonLandingLayout>
                    {props.children}
                </NonLandingLayout>
            )}
        </Fragment>
        )

    }
}

export default AuthoriseRoute;