import { Box, Flex, } from "rebass";

const contentLayout = ({children }: { children: JSX.Element }) => {
    return <Flex px={[1, 3]} py={[1, 3]}>
        <Box mx='auto' />
        {children}
        <Box mx='auto' />
    </Flex>
}

export default contentLayout;