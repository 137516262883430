import { Heading, Card, Box } from "rebass";
import PaymentForm from "./PaymentForm";
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, AuthActionTypes } from '../../store/auth';
import { redirect } from "react-router-dom";
import { IAppState } from "../../store";
import { useEffect } from "react";
import { Token } from "@stripe/stripe-js";


export const PaymentFormContainer = (props: any) => {
  const dispatch = useDispatch();
  const chargeStatus = useSelector((state: IAppState) => state.auth.chargeStatus);
  const currentUser = useSelector((state: IAppState) => state.auth.currentUser);
  const updatePaymentStatus = (status: string) => dispatch({ type: AuthActionTypes.UPDATE_PAYMENT_STATUS, 'status': 'processing' });
  const createSubscription = (firebase_id: string, token: Token) => dispatch(actionCreators.createSubscription(firebase_id, token));

  useEffect(() => {
    if (currentUser && currentUser.stripe_subscription_id) {
      redirect("/profile")
    }
  }, [currentUser])

  return (
    <Card
      width={["95%", "95%", 3 / 4]}
      p={[3, 5]}
      my={3}
      mx={'auto'}
      bg="#f6f6ff"
    >
      <Heading mb={4} color="#6e37ff" fontFamily="Lato">
        Plan Payment
      </Heading>
      <Box width={[1]}>
        <PaymentForm
          setPaymentProcessingStatus={updatePaymentStatus}
          chargeStatus={chargeStatus}
          createSubscription={createSubscription}
          currentUser={currentUser}
        />
      </Box>
    </Card>
  )
}


export default PaymentFormContainer;