
import { Box, Text, Flex, Image } from "rebass";
import './landingContent.css'
import examplePoll from '../../../images/example_poll.png'

const PollContent = () => {
    return <Flex my={[5, 0]}>
        <Image id="pollImage" minWidth={['275px']} src={examplePoll} style={{ borderRadius: '5px', height: '500px' }} />
        {/* Image not shown on mobile (see css file) */}
        <Box ml={[0, 5]} >
            <Flex>
                <Box ml={-2} width={['100px']} alignSelf="flex-start">
                    <svg style={{ float: "left", marginLeft: "-10px", marginRight: "10px" }} width="100%" height="100%" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4856 14.5638C13.4856 11.8355 13.4856 10.4714 14.0585 9.6828C14.5583 8.99495 15.3264 8.55146 16.172 8.46259C17.1414 8.3607 18.3228 9.04277 20.6856 10.4069L40.0356 21.5787C42.3984 22.9428 43.5797 23.6249 43.9762 24.5154C44.322 25.2921 44.322 26.1791 43.9762 26.9558C43.5797 27.8463 42.3983 28.5283 40.0356 29.8925L20.6856 41.0642C18.3228 42.4284 17.1414 43.1104 16.172 43.0086C15.3264 42.9197 14.5583 42.4762 14.0585 41.7883C13.4856 40.9997 13.4856 39.6356 13.4856 36.9073L13.4856 14.5638Z" fill="#4CDC4C" />
                    </svg>
                </Box>
                <Box mt={2} mb={2} fontFamily="Lato">
                    <Text fontSize={[4]} className="dp_heading">Find a date</Text>
                    <Text
                        mt={[0, 2, 3]}
                        fontSize={[2, 3]}
                        pr={1}
                        fontWeight={500}
                        letterSpacing={"0.04em"}
                        color="white">
                        Create a quick poll to see what dates people can make.</Text>

                    <hr style={{ backgroundColor: 'white' }} />

                    <Box className="contentDetails" mt={3} fontFamily="Lato" letterSpacing={"0.04em"}>
                        <Text my={2} fontSize={[1, 2, 3]} color={"white"}>1. Describe your poll</Text>
                        <Text my={2} fontSize={[1, 2, 3]} color={"white"}>2. Choose some dates and a location</Text>
                        <Text my={2} fontSize={[1, 2, 3]} color={"white"}>3. Share the link with friends</Text>
                    </Box>

                </Box>

            </Flex>

        </Box>
    </Flex >
}

export default PollContent;