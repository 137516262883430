import React from 'react';
import ViewPollButton from './viewPollButton';
import { Card, Flex, Button, Text, Box } from 'rebass';
import ShareButtons from '../polls/creation/buttons/shareButtons';
import DashboardModal from './dashboardModal';
import { useDispatch } from "react-redux";
import { actionCreators } from '../../store/polls';
import { FireBaseAuthContext, IAuthContext } from "../firebase/firebase";

const PollCard =  ({ item, windowUrl }: any) => {
    const dispatch = useDispatch();
    const { authUser } = React.useContext(FireBaseAuthContext) as IAuthContext;

    const onDeletePoll = (id: string) => {
        if (authUser){
            dispatch(actionCreators.deletePoll(id, authUser.uid, true))
        }
    }

    const renderRepondees = (userResponses: any[]) => {
        switch (userResponses.length) {
            case 0:
                return null
            case 1:
                return userResponses[0].name
            default:
                const ur = userResponses.map(x => x.name);
                ur.splice(ur.length - 1, 0, "&")
                const strUr = ur.slice(0, ur.length - 2).join(", ") + " & " + ur[ur.length - 1]
                return strUr
        }
    }


    return <Card
        width={[1, 1, 0.8]}
        p={[2, 4]}
        my={2}
        mx="auto"
        bg="#f6f6ff"
        style={{ boxShadow: "0 2px 16px rgba(0, 0, 0, 0.25)" }}
        key={item.id}>
        <Flex>
            <Text fontFamily="Lato" fontSize={4} fontWeight='Bold'>{item.title}</Text>
            <Box mx='auto' />
            {item.privateEvent
                ? <Flex>
                    <Text alignSelf="center" fontWeight="bold" textAlign="center" fontFamily="Lato" mr={2}>
                        <span role="img" aria-label="locked">🔒</span>
                    </Text>
                    <Text alignSelf="center" fontFamily="Lato" color="deeppink" fontSize={3} fontWeight={600}>Restricted</Text>
                </Flex>
                : null
            }

        </Flex>


        <Flex>
            <Box width={[0.7, 1]}>

                <Text fontFamily="Lato" fontSize={2}>{item.description}</Text>
                {item.location && item.location.place_name
                    ? <Text my={2} color={"darkslateblue"} fontFamily="Lato" fontSize={1}>{item.location && item.location.place_name ? item.location.place_name : null}</Text>
                    : null}
            </Box>
            <Box mx={'auto'} />
            <Box alignSelf="center">
                <Box my={1} mx={2}>
                    <ViewPollButton id={item.id} />
                </Box>

                <Button data-toggle="modal" data-target={`#konqur_poll${item.id}`} className="deletePollButton" width={75} my={1} mx={2} bg='red'>
                    <Text fontSize={1} fontFamily="Lato">
                        Delete
                 </Text>
                </Button>
                <DashboardModal id={item.id} modalName={`konqur_poll${item.id}`} onDeleteEvent={onDeletePoll} title={item.title} />

            </Box>
        </Flex>
        <Flex>
            <Flex fontFamily="Lato" my={3} fontSize={2}>
                <Text color='#6e37ff'>{item.userResponses.length}</Text>
                <Text ml={1} >{item.userResponses.length === 1 ? 'response' : 'responses'}</Text>
                <Text mr={1} >&nbsp;{item.userResponses.length > 0 ? 'from: ' : null}</Text>
                <Text color='#6e37ff'>{renderRepondees(item.userResponses)}</Text>

            </Flex>
        </Flex>
        <ShareButtons
            title={item.title}
            url={windowUrl + `poll/fill/${item.id}`}
            itemType="poll"
        />
    </Card>
}

export default PollCard;