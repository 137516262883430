
import React from 'react';
import { actionCreators, PollActionTypes } from '../../store/polls';
import { getEvents } from '../../store/events';
import { Label, Radio } from '@rebass/forms'
import { Card, Flex, Heading, Box } from 'rebass';
import StartNewPollButton from './startNewPollButton';
import './buttons.css'
import StartNewEventButton from "./startNewEventButton";
import { useDispatch, useSelector } from "react-redux";
import EventCard from './eventCard';
import PollCard from './pollCard';
import { FireBaseAuthContext, IAuthContext } from "../firebase/firebase";
import { IAppState } from '../../store';
import { useNavigate } from 'react-router-dom';

export const DashboardContainer = () => {
    const dispatch = useDispatch();
    const userPolls = useSelector((store: IAppState) => store.cre.polls);
    const userEvents = useSelector((store: IAppState) => store.env.events);
    const currentUser = useSelector((store: IAppState) => store.auth.currentUser);
    const [showPolls, setShowPolls] = React.useState('poll');
    const { ready, authUser } = React.useContext(FireBaseAuthContext) as IAuthContext;
    const navigate = useNavigate();

    React.useEffect(() => {
        if (ready && authUser){
            dispatch(actionCreators.getUserCreatedPolls(authUser.uid));
            dispatch(getEvents(authUser.uid));
        }
    }, [dispatch, authUser, ready])

    
    const createNewEvent = () => {
        navigate("/event/create");
    }

    const updateFormStatus = (formStatus: string) => {
        dispatch({ type: PollActionTypes.UPDATE_FORM_STATUS, 'formStatus': formStatus });
    }

    const renderPollCards = () => {
        const windowUrl = window.location.href.substring(0, window.location.href.length - 9);
        if (userPolls && userPolls.length > 0) {
            return userPolls.map((x, i) => {
                return <PollCard key={"Poll_" + i} item={x} windowUrl={windowUrl} />
            })
        }
        return
    }

    const renderEventCards = () => {
        const windowUrl = window.location.href.substring(0, window.location.href.length - 9);
        if (userEvents && userEvents.length > 0) {
            return userEvents.map((x, i) => {
                return <EventCard key={"Event_" + i} item={x} windowUrl={windowUrl} />
            })
        }
        return
    }

    const renderDash = () => {
        return <Box>
            <Card
                width={[1]}
                p={[2, 4]}
                mb={[2, 3]}
                bg="#f6f6ff"
                style={{ boxShadow: "0 2px 16px rgba(0, 0, 0, 0.25)" }}

            >
                <Flex width={[1]} flexWrap={"wrap"}>
                    <Heading ml={[0, '100px']} fontSize={['20px', '36px']} mb={3} color="#6e37ff" fontFamily="Lato"> Welcome back, {currentUser.name}</Heading>
                    <Box mx='auto' />
                    <Flex flexWrap="wrap" >
                        <Box my={1}>
                            <StartNewPollButton updateStatus={(status: string) => updateFormStatus(status)} />
                        </Box>
                        <Box mx={['8px']} />
                        <Box my={1}>
                            <StartNewEventButton updateStatus={() => createNewEvent()} />
                        </Box>
                    </Flex>
                </Flex>

            </Card>
            <Card
                width={[1, 1, 0.8]}
                p={[2, 4]}
                mx="auto"
                bg="#f6f6ff"
                style={{ boxShadow: "0 2px 16px rgba(0, 0, 0, 0.25)" }}>
                <Flex fontFamily='Lato' ml={[0, '100px']} width="200px">
                    <Label>
                        <Radio
                            checked={showPolls === 'poll'}
                            name='pollEvent'
                            id='polls'
                            value='poll'
                            color="green"
                            onChange={() => setShowPolls("poll")}
                        />

                Polls
                </Label>
                    <Box mx='4px' />
                    <Label>
                        <Radio
                            checked={showPolls === 'event'}
                            name='pollEvent'
                            id='events'
                            value='event'
                            color="purple"
                            onChange={() => setShowPolls("event")}
                        />
                Events
                </Label>

                </Flex>
            </Card>
            {showPolls === 'poll'
                ? renderPollCards()
                : renderEventCards()
            }
        </Box>
    }

    return currentUser ? renderDash() : <div />

}
export default DashboardContainer;