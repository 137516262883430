import { db } from "../components/firebase/firebase";
import { FIRESTORE_COLLECTIONS } from "./collections";
import { deleteEvent } from "./events";
import { actionCreators as pollActionCreators } from "./polls";
import { Reducer } from "redux";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { IUser } from "../models/user";
import { getFireBaseTimeStamp } from "../components/firebase/utils";
import { addCustomClaim, cancelPro, deleteFirebaseAuthUser, upgradeToPro } from "../components/firebase/firebase";


export enum AuthActionTypes {
    GET_USER = 'GET_USER',
    CREATE_USER = 'CREATE_USER',
    DELETE_USER = 'DELETE_USER',
    CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION',
    CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
    UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS',
}

export interface IAuthState {
    readonly currentUser: any;
    readonly notificationItem: any;
    readonly chargeStatus: string;
}


const initialState: IAuthState = {
    currentUser: null,
    notificationItem: {
        title: '',
        body: '',
        color: ''
    },
    chargeStatus: 'No Status',
}

export const actionCreators = {
    createUser: (firebaseId: string, providerId: string, profileName: string, email: string) => async (dispatch: any) => {
        try {
            const docRef = await addDoc(collection(db, FIRESTORE_COLLECTIONS.USERS), {
                firebase_id: firebaseId,
                provider_id: providerId,
                name: profileName,
                email: email,
                plan_tier: 'free',
                date_created: getFireBaseTimeStamp(),
                last_updated: getFireBaseTimeStamp()
            });
            await addCustomClaim({ "docId": docRef.id })
            dispatch({ type: AuthActionTypes.CREATE_USER });
            dispatch(actionCreators.getUser(docRef.id));
        } catch (error) {
            console.error("createUser", error)
        }
    },
    getUser: (docId: string) => async (dispatch: any) => {
        try {
            const res = await getDoc(doc(collection(db, FIRESTORE_COLLECTIONS.USERS), docId))
            if (res.exists()){
                const user = res.data() as IUser;
                user['id'] = res.id;
                dispatch({ type: AuthActionTypes.GET_USER, user });
            }
        } catch (error) {
            console.error("getUser", error)
        }
    },
    getUserByFirebaseId: (authUser: any) => async (dispatch: any) => {
        try {
            const querySnapShot = await getDocs(query(collection(db, FIRESTORE_COLLECTIONS.USERS), where("firebase_id", "==", authUser.uid)));
            if (querySnapShot.empty) {
                dispatch(actionCreators.createUser(authUser.uid,
                    authUser.providerData[0].uid,
                    authUser.displayName,
                    authUser.email));
            }
            else {
                let user
                querySnapShot.forEach(x => {
                    if (x.exists()) {
                        user = x.data() as IUser;
                        user['id'] = x.id;
                    }
                })
                dispatch({ type: AuthActionTypes.GET_USER, user });
            }
        } catch (error) {
            console.error("getUserByFirebaseId", error)
        }
    },
    deleteUser: (authUser: any, signOutFunc: any) => async (dispatch: any) => {
        
        let querySnapShot = await getDocs(query(collection(db, FIRESTORE_COLLECTIONS.EVENTS), where("user", "==", authUser.uid)));
        querySnapShot.forEach(async x => {
            if (x.exists()) {
                await deleteEvent(x.id, authUser.uid, false)
            }
        })
        
        let querySnapShot2 = await getDocs(query(collection(db, FIRESTORE_COLLECTIONS.POLLS), where("user", "==", authUser.uid)));

        querySnapShot2.forEach(async x => {
            if (x.exists()) {
                await pollActionCreators.deletePoll(x.id, authUser.uid, false)
            }
        })
        
        let querySnapShot3 = await getDocs(query(collection(db, FIRESTORE_COLLECTIONS.USERS), where("firebase_id", "==", authUser.uid)));
        querySnapShot3.forEach(async x => {
            if (x.exists()) {
                await deleteDoc(doc(collection(db, FIRESTORE_COLLECTIONS.USERS), x.id));
            }
        })
        await deleteFirebaseAuthUser();
        await signOutFunc();
        dispatch({ type: AuthActionTypes.DELETE_USER });
    },
    createSubscription: (firebase_id: string, stripeToken: any) => async (dispatch: any) => {
        const user = await upgradeToPro({ firebase_id, stripeToken });
        dispatch({ type: AuthActionTypes.UPDATE_PAYMENT_STATUS, 'status': 'complete' });
        dispatch({ type: AuthActionTypes.CREATE_SUBSCRIPTION, user });
    },
    cancelSubscription: () => async (dispatch: any) => {
        const user = await cancelPro();
        dispatch({ type: AuthActionTypes.UPDATE_PAYMENT_STATUS, 'status': 'complete' });
        dispatch({ type: AuthActionTypes.CANCEL_SUBSCRIPTION, user });
    },
}




const reducer: Reducer<IAuthState, any> =(state = initialState, action) => {
    switch (action.type) {
        case AuthActionTypes.GET_USER:
            return {
                ...state,
                currentUser: action.user || false,
            };
        case AuthActionTypes.CREATE_USER: {
            return {
                ...state
            }
        }
        case AuthActionTypes.DELETE_USER:
            return {
                ...state,
                currentUser: null,
                notificationItem: {
                    title: 'Account Closed',
                    body: 'Your account has been closed'
                }
            }
        case AuthActionTypes.CREATE_SUBSCRIPTION:
            return {
                ...state,
                currentUser: action.user || false,
                notificationItem: {
                    title: 'Subscription Created',
                    body: 'Congratulations you are now a Pro user!'
                }
            }
        case AuthActionTypes.CANCEL_SUBSCRIPTION:
            return {
                ...state,
                currentUser: action.user || false,
                notificationItem: {
                    title: 'Subscription Cancelled',
                    body: 'Your subscription has been cancelled with immediate effect'
                }
            }
        case AuthActionTypes.UPDATE_PAYMENT_STATUS:
            return {
                ...state,
                chargeStatus: action.status
            }
        default:
            return state;

    }
}

export default reducer;