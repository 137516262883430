import { useEffect, useState } from 'react';
import { getEvent, updateEventResponse } from '../../../store/events';
import { useDispatch, useSelector } from "react-redux";
import { Flex, Link, Card, Box, Text } from 'rebass';
import EventTitle from './eventTitle';
import EventLocation from './eventLocation';
import EventDate from './eventDate';
import EventSignUp from './eventSignup';
import CommentsContainer from '../../comments/commentsContainer';
import EventResponseInfo from "./eventReponseInfo";
import ShareButtons from "../../polls/creation/buttons/shareButtons";
import moment from 'moment';
import DescriptionMap from "../../shared/descriptionMap";
import { IAppState } from '../../../store';

const EventResponseContainer =  () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((store:IAppState) => store.auth.currentUser);
    const currentEvent = useSelector((store:IAppState) => store.env.currentEvent);
    const [eventId, setEventId] = useState<string | undefined>();
    const [url, setUrl] = useState<string | undefined>();
    const [calendarLink, setCalendarLink] = useState("");

    useEffect(() => {
        const windowUrl = window.location.href.split("/");
        const id = windowUrl[windowUrl.length - 1]
        setUrl(window.location.href);
        if (id){
            setEventId(id);
            dispatch(getEvent(id));
        }
        
    }, [dispatch]);


    useEffect(() => {
        if (currentEvent) {
            const date = moment(currentEvent.eventDate).format("YYYYMMDD");
            const dayAfter = moment(currentEvent.eventDate).add(1, 'd').format("YYYYMMDD");
            const link = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${date}/${dayAfter}&text=${currentEvent.eventTitle}&location=&details=${currentEvent.eventDescription}`
            setCalendarLink(link);
        }
    }, [currentEvent])

    const updateStatus = (status: string) => {
        if (eventId){
            dispatch(updateEventResponse(eventId, status, currentUser.id));
        }
    }
    // Currently all events are restrcited to users who have logged in (otherwise how to track response?)
    const renderRestrictedView = () => {
        return <Flex>
            <Card
                width={[1, 1, 4 / 5]}
                p={[3, 5]}
                my={[3, 5]}
                mx="auto"
                bg="#f6f6ff"
               >
                <Text fontFamily='Lato' textAlign="center" fontWeight={600} fontSize={4} color={"#6e37ff"}>
                    Sorry you must be a registered user to view this event.
                </Text>
            </Card>
        </Flex>
    }

    const renderEvent = () => {
        if (currentEvent) {
            return <Box>
                <Flex mb={2} mt={3} alignItems="top" width={1} flexWrap="wrap">
                    <Box >
                        <EventTitle title={currentEvent.eventTitle} description={currentEvent.eventDescription} />
                        {currentEvent.eventLocation
                            ? <EventLocation location={currentEvent.eventLocation} />
                            : null
                        }


                        <Box my={2}>
                            <EventDate date={currentEvent.eventDate || ""} />
                            <Link fontSize={1} fontFamily="Lato" href={calendarLink}>Add to Google Calendar</Link>
                        </Box>

                        <ShareButtons title={currentEvent.eventTitle}
                            url={url}
                            itemType="event"
                        />

                    </Box>
                    <Box mx='auto' />
                    <Box width={[1, 0.4]}  >
                        {currentEvent.eventLocation
                            ? <DescriptionMap location={currentEvent.eventLocation} />
                            : null
                        }
                    </Box>
                </Flex>
                <Flex>
                    <EventResponseInfo currentEvent={currentEvent} />
                    <Box mx="auto" />
                </Flex>
                <hr />
                <Flex>
                    <EventSignUp
                        updateStatus={updateStatus}
                        currentEvent={currentEvent} currentUser={currentUser} />
                    <Box mx='auto' />
                </Flex>

                <hr />
                <Box mt={4}>
                    <CommentsContainer itemType="events" />
                </Box>

            </Box>
        }
        return
    }

    if (!currentUser) {
        return renderRestrictedView();
    } else
        return <Flex style={{ height: '100%' }} py={1}>
            <Card
                height="min-content"
                width={[1, 1, 4 / 5]}
                p={[3, 5]}
                my={[1, "auto"]}
                mx={[1, "auto"]}
                bg="#f6f6ff"
                style={{ boxShadow: "0 2px 16px rgba(0, 0, 0, 0.25)", borderRadius: 5 }}

            >
                {renderEvent()}
            </Card>
        </Flex>
}

export default EventResponseContainer;