import { Box, Flex } from "rebass";
import Footer from './components/footer';
import Helmet from 'react-helmet';
import Header from './components/header'

export const NonLandingLayout = (props: any) => {
    return <Flex className="back" flexDirection="column">
        <Header />
        <Flex flexDirection="column" className="jazzyBackground" style={{ overflowY: 'auto' }} height="100%" flex="1 1 auto" >
            <Helmet title="Konqur - Event Scheduling" />
            {props.children}
            <Box my='auto' />
            <Footer />
        </Flex>
    </Flex>
};

export default NonLandingLayout;