import { Box, Flex, Text } from "rebass";
import { Link as ReactLink } from 'react-router-dom';

export const footer = () => (
        <Flex fontFamily={'Lato'} fontSize={1} p={1} bg="black">
                <Box mx='auto' />
                <ReactLink to={"/about"}>
                        <Text style={{ textDecoration: 'underline' }}
                                color={'#6E37FF'} fontSize={[12, 14]} fontWeight={[400, 600]} px={2}>About</Text>
                </ReactLink>
                <ReactLink to={"/privacy"}>
                        <Text style={{ textDecoration: 'underline' }} color={'#6E37FF'} fontSize={[12, 14]} fontWeight={[400, 600]} px={2}>Privacy</Text>
                </ReactLink>
                <ReactLink to={"/cookies"}>
                        <Text style={{ textDecoration: 'underline' }} color={'#6E37FF'} fontSize={[12, 14]} fontWeight={[400, 600]} px={2}>Cookie Policy</Text>
                </ReactLink>
                <Box mx='auto' />
        </Flex>
)

export default footer;