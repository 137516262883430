import React from 'react';
import { actionCreators, PollActionTypes } from '../../../store/polls';
import { useDispatch, useSelector } from 'react-redux'
import DateTableDesktop from './desktop/dateTableDesktop'
import DateTableMobile from './mobile/dateTableMobile'
import SubmitButton from './buttons/submitButton'
import SubmitSummary from './submitSummary'
import CommentsContainer from '../../comments/commentsContainer';
import { Card, Flex, Text, Box } from "rebass";
import { FireBaseAuthContext, IAuthContext } from "../../firebase/firebase";
import { IAppState } from '../../../store';
import { useParams } from 'react-router-dom';
import deepcopy from 'deepcopy';
import { IPoll2 } from '../../../models/poll';
import { Timestamp } from 'firebase/firestore';

const FillContainer = (props: any) => {
    const { accessToken, authUser } = React.useContext(FireBaseAuthContext) as IAuthContext;
    const currentUser = useSelector((state: IAppState) => state.auth.currentUser);

    const [submitted, setSubmitted] = React.useState(false);
    const [formClass, setFormClass] = React.useState("needs-validation");
    const [alreadyFilledOut, setAlreadyFilledOut] = React.useState(false);
    const { id } = useParams<{ id: string | undefined }>();
    const mostNumberPicked = useSelector((state: IAppState) => state.cre.mostNumberPicked)
    const userResponse = useSelector((state: IAppState) => state.cre.userResponse);
    const kq = useSelector((state: IAppState) => state.cre.kq);
    const dispatch = useDispatch();

    const getCookie = (id: string) => {
        let konqId = "konqurid_" + id + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(konqId) === 0) {
                return c.substring(konqId.length, c.length);
            }
        }
        return "";
    }

    React.useEffect(() => {
        if (id) {
            dispatch(actionCreators.getPoll(id));
            let konqurCompleteCookie = getCookie(id);
            if (konqurCompleteCookie !== "") {
                setAlreadyFilledOut(true);
            }
        }

    }, [dispatch, id]);

    React.useEffect(() => {
        if (accessToken && kq) {
            dispatch(actionCreators.getGCalEvents(accessToken, true)); //Cross compares with KQ and adds events to kqWithGCal
        }
    }, [accessToken, kq, dispatch])

    const onUpdateUserName = (name: string) => dispatch({ type: PollActionTypes.UPDATE_USER_NAME, 'name': name });
    const onUpdateUserResponse = (date: Timestamp) => dispatch({ type: PollActionTypes.UPDATE_USER_RESPONSE, 'date': date });

    const submitResponse = () => {
        setFormClass("needs-validation was-validated")
        if (userResponse && userResponse.name && kq) {
            setSubmitted(true);
            let userResponseObj = deepcopy(userResponse)
            userResponseObj.userId = authUser ? authUser.uid : ''
            dispatch(actionCreators.createReply(userResponse, kq.id));

        }
    }

    const renderRestrictedView = () => {
        return <Flex>
            <Card
                width={[1, 1, 4 / 5]}
                p={[3, 5]}
                my={[3, 5]}
                mx="auto"
                bg="#f6f6ff"
            >
                <Text fontFamily='Lato' fontWeight={600} fontSize={5} color={"#6e37ff"}>
                    Sorry this is a Restricted Poll, you must be a registered user to view.
                </Text>
            </Card>
        </Flex>
    }

    const renderFillOutView = () => {
        return <Flex py={1}>
            <Card
                width={[1, 1, 4 / 5]}
                p={5}
                className="desktop"
                my="auto"
                mx="auto"
                bg="#f6f6ff"
            >
                <DateTableDesktop
                    alreadyFilledOut={alreadyFilledOut}
                    userResponse={userResponse}
                    updateResponse={(date: Timestamp) => onUpdateUserResponse(date)}
                    updateUserName={(e) => onUpdateUserName(e.target.value)}
                    formClass={formClass}
                    kq={kq as IPoll2}
                />

                <Flex mt={1}>
                    <SubmitButton
                        alreadyFilledOut={alreadyFilledOut}
                        submitResponse={() => submitResponse()} />
                    <Box mx='auto' />
                    <Text fontSize={1} fontFamily='Lato' alignSelf="center"><span role="img" aria-label="bell">🔔</span> = Existing Events in your Calender</Text>
                </Flex>
                <hr />
                <CommentsContainer itemType="polls" />
            </Card>

            <Card p={2} width={[1]} className="mobile">
                <DateTableMobile
                    alreadyFilledOut={alreadyFilledOut}
                    userResponse={userResponse}
                    updateResponse={(date: Timestamp) => onUpdateUserResponse(date)}
                    updateUserName={(e: any) => onUpdateUserName(e.target.value)}
                    kq={kq as IPoll2}
                    mostNumberPicked={mostNumberPicked}
                >
                    <SubmitButton
                        alreadyFilledOut={alreadyFilledOut}
                        submitResponse={() => submitResponse()} />
                </DateTableMobile>

                <Card
                    mt={2}
                    p={2}
                    width={[1]}
                    bg={'white'}
                >
                    <CommentsContainer itemType="polls" />
                </Card>
            </Card>

        </Flex >
    }
    const renderSubmitted = () => {
        return <Flex>
            <Card
                width={[1, 1, 4 / 5]}
                p={[3, 5]}
                my={[3, 5]}
                mx="auto"
                bg="#f6f6ff"
            >
                <SubmitSummary />
            </Card>
        </Flex>
    }


    if (kq) {
        if (kq.privateEvent && !currentUser) {
            return renderRestrictedView();
        } else if (submitted === false) {
            return renderFillOutView();
        } else {
            return renderSubmitted();
        }
    }
    return <div />
}

export default FillContainer;