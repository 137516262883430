import { GeoPoint, Timestamp } from "firebase/firestore";
import moment from "moment";

export const getFireBaseTimeStamp = () => Timestamp.now()

export const getDateFromFireBaseTimeStamp = (timeStamp: Timestamp) => {
    return moment.unix(timeStamp.seconds)
}

export const getFireBaseTimeStampFromMomentDate = (momentDate: moment.Moment) => {
    return Timestamp.fromDate(momentDate.toDate());
}

export const getFireBaseTimeStampFromDate = (dateString: string) => {
    return Timestamp.fromDate(moment(dateString).toDate());
}

export const getFireBaseGeoPoint = (lat: any, lng: any) => {
    return new GeoPoint(parseFloat(lat), parseFloat(lng));
}
