import { Text, Button } from 'rebass'

const ProfileModal = ({ modalName, deleteAccount }: { modalName: string, deleteAccount: () => void }) => {
    return <div className="modal fade" id={modalName} data-backdrop="false" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Are you sure you want to delete your account?</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    This will delete your account and all events you created
                </div>
                <div className="modal-footer">
                    <Button
                        width={75} my={1} mx={1}
                        className="deletePollBackButton"
                        data-dismiss="modal"
                        bg="#6c757d">
                        <Text fontSize={1} fontFamily="Lato">Cancel</Text>
                    </Button>
                    <Button onClick={deleteAccount} className="deleteAccountButton" width={75} my={1} mx={2} bg='red'>
                        <Text fontSize={1} fontFamily="Lato">Delete</Text>
                    </Button>
                </div>
            </div>
        </div>
    </div>
};

export default ProfileModal;