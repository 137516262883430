
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { Reducer } from "redux";
import { db } from "../components/firebase/firebase";
import { IEvent } from "../models/event";
import { FIRESTORE_COLLECTIONS } from "./collections";

export enum EventActionTypes {
    CREATE_EVENT = "CREATE_EVENT",
    GET_EVENT = 'GET_EVENT',
    GET_EVENTS = 'GET_EVENTS',
    UPDATE_EVENT_RESPONSE = 'UPDATE_EVENT_RESPONSE',
    DELETE_EVENT = 'DELETE_EVENT',
}

export interface IEventState {
    readonly currentEvent: IEvent | null;
    readonly events: IEvent[] | null;
}

const initialState: IEventState = {
    currentEvent: null,
    events: null
}

export const createEvent = (event: IEvent, navigate: any) => async (dispatch: any) => {
    
    let result = await addDoc(collection(db, FIRESTORE_COLLECTIONS.EVENTS), event);
    event['id'] = result.id;
    dispatch({ type: EventActionTypes.CREATE_EVENT, event });
    navigate(`/event/response/${event.id}`)
}

export const getEvent = (eventId: string) => async (dispatch: any) => {
    const result = await getDoc(doc(collection(db, FIRESTORE_COLLECTIONS.EVENTS), eventId));
    if (result.exists()) {
        const item = result.data();
        if (item) {
            item["id"] = result.id;
        }
        dispatch({ type: EventActionTypes.GET_EVENT, event: item });

    } else {
        console.info("Event does not exist")
    }

}

export const getEvents = (userId: string) => async (dispatch: any) => {
    const querySnapshot = await getDocs(query(collection(db, FIRESTORE_COLLECTIONS.EVENTS), where("user", "==", userId)));
    const events: IEvent[] = [];
    querySnapshot.forEach((doc) => {
        const item = doc.data() as IEvent;
        item["id"] = doc.id;
        events.push(item);
    });
    dispatch({ type: EventActionTypes.GET_EVENTS, events });
}

export const deleteEvent = (id: string, userId: string, getAfter: boolean) => async (dispatch: any) => {
    const commentQuerySnapshot = await getDocs(collection(doc(collection(db, FIRESTORE_COLLECTIONS.EVENTS), id), FIRESTORE_COLLECTIONS.COMMENTS));
    let commentIds: string[] = [];
    commentQuerySnapshot.forEach((doc) => commentIds.push(doc.id));
    commentIds.forEach(async (cid) => {
        await deleteDoc(doc(collection(doc(collection(db, FIRESTORE_COLLECTIONS.EVENTS), id), FIRESTORE_COLLECTIONS.COMMENTS), cid))
    });
    await deleteDoc(doc(collection(db, FIRESTORE_COLLECTIONS.EVENTS), id));
    dispatch({ type: EventActionTypes.DELETE_EVENT });
    if (getAfter) {
        dispatch(getEvents(userId));
    }
}

export const updateEventResponse = (eventId: string, status: string, userId: string) => async (dispatch: any) => {
    const eventDocRef = doc(collection(db, FIRESTORE_COLLECTIONS.EVENTS), eventId)
    const eventDoc = await getDoc(eventDocRef);
    if (eventDoc.exists()) {
        const item = eventDoc.data() as IEvent;
        if (item) {
            item["id"] = eventDoc.id;
        }

        let existingYes = item.yes ? item.yes.filter((x) => x !== userId) : [];
        let existingNo = item.no ? item.no.filter((x) => x !== userId) : [];
        let existingMaybe = item.maybe ? item.maybe.filter((x) => x !== userId) : [];
        switch (status) {
            case 'yes':
                existingYes.push(userId);
                break;
            case 'no':
                existingNo.push(userId);
                break;
            case 'maybe':
                existingMaybe.push(userId)
                break;
            default:
                break;
        }

        let updatedResults = {
            yes: existingYes,
            no: existingNo,
            maybe: existingMaybe,
            lastUpdated: Date.now()
        }
        await setDoc(eventDocRef, updatedResults, {merge: true})
        let result = await getDoc(eventDocRef);
        if (result.exists()){
            let updatedItem = result.data() as IEvent;
            updatedItem['id'] = result.id;
            dispatch({ type: EventActionTypes.UPDATE_EVENT_RESPONSE, event: updatedItem });
        }else {
            console.error('Error getting updated event doc')
        }

    } else {
        console.info("Can't update event as does not exist")
    }

}

const eventReducer: Reducer<IEventState, any>  = (state = initialState, action) => {
    switch (action.type) {
        case EventActionTypes.GET_EVENT:
            return {
                ...state,
                currentEvent: action.event,
            }
        case EventActionTypes.GET_EVENTS:
            return {
                ...state,
                events: action.events
            }
        case EventActionTypes.CREATE_EVENT:
            return {
                ...state,
                currentEvent: action.event,
            }
        case EventActionTypes.DELETE_EVENT:
            return {
                ...state
            }
        case EventActionTypes.UPDATE_EVENT_RESPONSE:

            return {
                ...state,
                currentEvent: action.event,
            }

        default:
            return state;

    }
}

export default eventReducer;
