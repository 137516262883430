import { useState, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import { Text } from 'rebass';
import { useSelector } from "react-redux";
import { IAppState } from '../../store';

const ToastNotification = () => {

  const [showA, setShowA] = useState(false);
  const { title, body } = useSelector((store: IAppState) => store.auth.notificationItem);
  const toggleShowA = () => setShowA(!showA);

  useEffect(() => {
    if (title) {
      setShowA(true);
    }
  }, [title])

  return <Toast
    style={{ position: 'absolute', top: 80, right: 3 }}
    delay={6000}
    autohide
    show={showA}
    onClose={toggleShowA}>
    <Toast.Header>
      <strong style={{ fontFamily: 'Lato' }} className="mr-auto">{title}</strong>
      {/* <small style={{ fontFamily: 'Lato' }} >11 mins ago</small> */}
    </Toast.Header>
    <Toast.Body>
      <Text fontFamily='Lato'>
        {body}
      </Text>
    </Toast.Body>
  </Toast>
}
export default ToastNotification;