import { Text, Flex, Link, Image } from 'rebass';

const ShareButtons = ({ itemType, title, url }: { itemType: string, url: string | undefined, title: string | undefined }) => {

    //  <p>Share Via , email, Facebook, sms if phone, </p>
    ////Messsegner Won't work on phone need desktop version}
    let emailString;
    let whatsAppString = `whatsapp://send?text=Let's Konqur this! - ${url}`
    if (itemType === 'poll') {
        emailString = `mailto:?Subject=Let's%20find%20a%20date%20for%20${title}%20with%20Konqur&body=${url}`;
    } else {
        emailString = `mailto:?Subject=You've%20been%20invited%20to%20${title}&body=${url}`;
    }
    var isMobile = /iPhone|Android/i.test(navigator.userAgent);
    // let twitterText = `Let's%20find%20a%20date%20for%20${props.title}%20with%20Konqur&body=${props.url}`;
    return <Flex alignItems="baseline">
        <Text mr={2} fontFamily="Lato" color="rgb(194, 0, 65);" fontSize={[1]}>Share</Text>
        {isMobile
            ? <Link href={whatsAppString} data-action="share/whatsapp/share">
                <Image ml={['-6px']} width={[40, 50]} src={require('../../../../images/whats_app.png')}
                    alt='Share via WhatsApp'
                />
            </Link>
            : null}
        <Link href={emailString}>
            <Image height={[28, 32]} width={[28, 32]} src={require('../../../../images/baseline_email_black_24dp.png')}
                alt='Email'
            />
        </Link>
        {/* <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-size="large" data-text={twitterText} data-show-count="false">Tweet</a>
            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
        {/* <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-show-count="false">Tweet</a>
            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
        {/* <a href={`fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789`}>
                <img height="32" width="32" src={require('../../../images/messenger.png')}
                    alt='Send In Messenger'
                />
            </a> */}
        {/* <a style={{ marginLeft: '10px' }} href={`fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789`}>
                <img height="32" width="32" src={require('../../../images/twitter.png')}
                    alt='Share via Twitter'
                />window.open('fb-messenger://share?link=' + encodeURIComponent(link) + '&app_id=' + encodeURIComponent(app_id));

            </a> */}
        {/* <a style={{ marginLeft: '10px' }} href={`fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789`}>
                <img height="32" width="32" src={require('../../../images/baseline_share_black_24dp.png')}
                    alt='Share'
                />
            </a>
            <a style={{ marginLeft: '10px' }} href="mailto:someone@example.com?Subject=Hello%20again">
                <img height="32" width="32" src={require('../../../images/baseline_email_black_24dp.png')}
                    alt='Email'
                />
            </a> */}

    </Flex>
}
export default ShareButtons;


