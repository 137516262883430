import { Flex, Box } from 'rebass';
import moment from 'moment';
import { IComment } from '../../models/comment';

const ExistingComment = ({ comment }: { comment: IComment }) => {
    return <Box mt={3} fontFamily='Lato' >
        <Box p={2} style={{ borderRadius: '2px', border: '1px solid #c7c7c7' }} width={[1, 0.5]}>
            <Box color={"rgb(194, 0, 65)"} fontSize={[2]}>{comment.comment} </Box>
            <Flex fontSize={[1]}>
                <Box mr={2}>{comment.name} </Box>
                <Box mx='auto' />
                <Box color='grey'>{moment(comment.dateCreated).format("DD-MM-YY HH:mm")} </Box>
            </Flex>
        </Box>
    </Box>
}

export default ExistingComment
