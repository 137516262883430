import { Button, Text } from "rebass";
import './buttons.css'
import { Link } from 'react-router-dom'

const EventPollButton = ({ id }: { id: string }) => {
    return <Link to={`/event/response/${id}`}>
        <Button
            className={'viewPollButton'}
            width={75}
            bg={'#4CDC4C'}>
            <Text fontSize={1} fontFamily="Lato">
                View
            </Text>
        </Button>
    </Link>
}
export default EventPollButton;