import { Text, Button } from 'rebass'

const CancelSubcriptionModal =  ({modalName, chargeStatus, cancelSub}: { id: string, modalName: string, chargeStatus: string, cancelSub: () => void }) => {
    return <div className="modal fade" id={modalName} data-backdrop="false" tabIndex={-1} role="dialog" aria-labelledby="cancel-subscriptionLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="cancel-subscriptionLabel">Are you sure you want to cancel your subscription?</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    This will cancel your subscription with immediate effect
                </div>
                <div className="modal-footer">
                    {chargeStatus !== "processing" ? (
                        <Button
                            my={1} mx={1}
                            className="deletePollBackButton"
                            data-dismiss="modal"
                            bg="#6c757d">
                            <Text fontSize={1} fontFamily="Lato">No I've changed my mind!</Text>
                        </Button>
                    ) : null}


                    <Button onClick={cancelSub} data-dismiss="modal" className="deleteAccountButton" my={1} mx={2} bg='red'>
                        <Text fontSize={1} fontFamily="Lato"> {chargeStatus === "processing" ? "Processing" : "Yes Cancel my subscription"} </Text>
                    </Button>
                </div>
            </div>
        </div>
    </div>
};

export default CancelSubcriptionModal;