import React from 'react';
import ViewEventButton from './viewEventButton';
import { Card, Flex, Text, Button, Box } from 'rebass';
import ShareButtons from '../polls/creation/buttons/shareButtons';
import DashboardModal from './dashboardModal';
import { useDispatch } from "react-redux";
import { deleteEvent } from "../../store/events";
import { FireBaseAuthContext, IAuthContext } from "../firebase/firebase";

const EventCard = ({ item, windowUrl } : any) => {
    const dispatch = useDispatch();
    const { authUser } = React.useContext(FireBaseAuthContext) as IAuthContext;

    const onDeleteEvent = (id: string) => {
        if (authUser) {
            dispatch(deleteEvent(id, authUser.uid, true))
        }
    }

    return <Card
        width={[1, 1, 0.8]}
        p={[2, 4]}
        my={2}
        mx="auto"
        bg="#f6f6ff"
        style={{ boxShadow: "0 2px 16px rgba(0, 0, 0, 0.25)" }}
        key={item.id}>
        <Flex>
            <Text fontFamily="Lato" fontSize={4} fontWeight='Bold'>{item.eventTitle}</Text>
            <Box mx='auto' />
            {/* {x.privateEvent
            ? <Flex>
                <Text alignSelf="center" fontWeight="bold" textAlign="center" fontFamily="Lato" mr={2}>
                    <span role="img" aria-label="locked">🔒</span>
                </Text>
                <Text alignSelf="center" fontFamily="Lato" color="deeppink" fontSize={3} fontWeight={600}>Restricted</Text>
            </Flex>
            : null
        } */}

        </Flex>


        <Flex>
            <Box width={[0.7, 1]}>

                <Text fontFamily="Lato" fontSize={2}>{item.eventDescription}</Text>
                {item.eventLocation && item.eventLocation.place_name
                    ? <Text my={2} color={"darkslateblue"} fontFamily="Lato" fontSize={1}>{item.eventLocation && item.eventLocation.place_name ? item.eventLocation.place_name : null}</Text>
                    : null}
            </Box>
            <Box mx={'auto'} />
            <Box alignSelf="center">
                <Box my={1} mx={2}>
                    <ViewEventButton id={item.id} />
                </Box>

                <Button data-toggle="modal" data-target={`#konqur_poll${item.id}`} className="deletePollButton" width={75} my={1} mx={2} bg='red'>
                    <Text fontSize={1} fontFamily="Lato">
                        Delete
                    </Text>
                </Button>
                <DashboardModal id={item.id} modalName={`konqur_poll${item.id}`} onDeleteEvent={onDeleteEvent} title={item.eventTitle} />
            </Box>
        </Flex>
        {/* <Flex>
        <Flex fontFamily="Lato" mv={3} fontSize={2}>
            <Text color='#6e37ff'>{x.userResponses.length}</Text>
            <Text ml={1} >{x.userResponses.length === 1 ? 'response' : 'responses'}</Text>
            <Text mr={1} >&nbsp;{x.userResponses.length > 0 ? 'from: ' : null}</Text>
            <Text color='#6e37ff'>{renderRepondees(x.userResponses)}</Text>

        </Flex>
    </Flex> */}
        <ShareButtons
            title={item.eventTitle}
            url={windowUrl + `event/response/${item.id}`}
            itemType="event"
        />
    </Card>
}

export default EventCard;