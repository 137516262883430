import { useMemo, useState } from "react";
import PollFormButtons from "./buttons/pollFormButtons";
import { Card, Box, Heading, Text, Flex } from "rebass";
import { Utils } from "../../../utils/utils";

const PollConfirm = (props: any) => {
  const [emailFormClass, setEmailFormClass] = useState('needs-validation')
  const [adminFormClass, setAdminFormClass] = useState('form-control')

  const updateStatus = (e: any) => {
    if (props.notifyAdminResponse) { //Only validate if notify checkbox checked. 
      if (e === 'datePick') { //Allow going backwards even if no email. 
        props.updateStatus(e)
      } else if (props.adminEmail) { //Only allow forward click if email exists. 
        if (Utils.validateEmail(props.adminEmail)) {
          setAdminFormClass('form-control')
          setEmailFormClass('needs-validation was-validated')
          props.updateStatus(e)
        }
        else {
          setAdminFormClass('form-control is-invalid')
        }
      }
    } else {
      props.updateStatus(e)
    }

  }


  const generateAdminEmailPart = () => {
    return <Box my={3} width={[1, 1 / 2]} >
      <form className={emailFormClass} noValidate>
        <div className="form-group">
          <input
            value={props.adminEmail}
            style={{ fontFamily: 'Lato' }}
            onChange={(e) => props.updateAdminEmail(e.target.value)}
            className={adminFormClass}
            id="admin_email"
            required
            type="email"
            placeholder="Your email"
          />
          <div className="invalid-feedback">
            <Text fontFamily="Lato" my={2} fontSize={[2]}>
              Add your email address, or unselect notify checkbox.
            </Text>
          </div>
        </div>
      </form>
    </Box>
  }


  const renderDateOptions = useMemo(() => {
    const sortedDates = props.selectedDates.sort(function (a: any, b: any) {
      return a - b;
    });

    let colours = ["#C20041", "#FF0115", "#FF8124", "#6e37ff", "#22aa22"];
    return sortedDates.map((x: any, i: number) => {
      return (
        <Flex key={x.toString() + i}>
          <Text
            width={"30px"}
            p={2}
            my={1}
            mr={1}
            fontSize={2}
            fontFamily="Lato"
            fontWeight='bold'

            color="white"
            bg={i === 0 ? "#22aa22" : colours[(i - 1) % colours.length]}
          > </Text>
          <Text
            p={2}
            fontSize={2}
            fontFamily="Lato"
            width={[1, 1 / 2]}
            color="white"
            my={1}
            bg={i === 0 ? "#22aa22" : colours[(i - 1) % colours.length]}
          >
            {x.format("dddd, MMMM Do YYYY")}
          </Text>
        </Flex>
      )
    });
  }, [props.selectedDates])



  return (
    <Card
      width={[1, 1, "700px"]}
      p={[3, 5]}
      my={[3]}
      mx="auto"
      bg="#f6f6ff"
    >
      <Text fontFamily="Lato" color="grey">Step 3: </Text>
      <Heading fontFamily="Lato" color="#6e37ff">Confirm</Heading>


      <Text fontFamily="Lato" my={2} fontSize={[3]}>
        {props.title}
      </Text>
      <Text fontFamily="Lato" my={3} fontSize={[2]}>
        {props.description}
      </Text>
      {props.privateEvent
        ? <Flex>
          <Text alignSelf="center" fontWeight="bold" textAlign="center" fontFamily="Lato" mr={2}>
            <span role="img" aria-label="locked">🔒</span>
          </Text>
          <Text alignSelf="center" fontFamily="Lato" color="deeppink" fontSize={3} fontWeight={600}>Private</Text>
        </Flex>
        : null
      }
      <Text fontFamily="Lato" my={2} fontSize={[1]}>
        {props.location.place_name
          ? (<Flex>
            <Box><img height="18" width="18" alt="locationicon" src={require('../../../images/baseline_location_on_black_18dp.png')} /></Box>
            <Box> {props.location.place_name}</Box>
          </Flex>)
          : null
        }
      </Text>

      <Text fontFamily="Lato" my={3} fontSize={[2]} fontWeight="bold">
        Date Options:
      </Text>
      {renderDateOptions}
      <div style={{ marginTop: '5px' }}>
        <div className="custom-control custom-checkbox">
          <input type="checkbox"
            className="custom-control-input"
            checked={props.notifyAdminResponse}
            id="notifyCheck" onChange={() => props.notifyAdminResponseChange()} />
          <label className="custom-control-label" htmlFor="notifyCheck">Notify me when someone responds</label>
        </div>
        {
          props.notifyAdminResponse
            ? generateAdminEmailPart()
            : null
        }
      </div>


      <PollFormButtons
        updateStatus={(e: any) => updateStatus(e)}
        forwardStatus={props.forward}
        backStatus={props.back}
        forwardName={'Confirm'}
        backName={'Back'}
      />
    </Card>
  )

}

export default PollConfirm;


